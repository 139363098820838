import { combineReducers } from "redux";
import userReducer from "./userReducer";
import usersReducer from "./usersReducer";
import reRender from "./reRender";
import newReducer from "./newReducer";
import clickDetection from "./clickDetection";
import clickDetection2 from "./clickDetection2";
import clickDetection3 from "./clickDetection3";
import renderLead from "./renderLead";
import statusReducer from "./statusReducer";
import sourcesReducer from "./sourcesReducer";
import courseReducer from "./courseReducer";
import leadConvertTypeReducer from "./leadConvertTypeReducer";
import sidebarLockerReducer from "./sidebarLockerReducer";
import timerReducer from "./timerReducer";
import filterReducer from "./filterReducer";
import followupReducer from "./followupReducer";
import callDataReducer from "./callDataReducer";
import callQueueReducer from "./callQueueReducer";
import callTimerReducer from "./callTimerReducer";
import systemSettingsReducer from "./systemSettingsReducer";
import visitAndDemoReducer from "./visitAndDemoReducer";
import renderFieldMarketingUsersTasks from "./renderFieldMarketingUsersTasks";

const rootReducer = combineReducers({
  newReducer,
  userReducer,
  usersReducer,
  reRender,
  clickDetection,
  clickDetection2,
  clickDetection3,
  renderLead,
  statusReducer,
  sourcesReducer,
  courseReducer,
  leadConvertTypeReducer,
  sidebarLockerReducer,
  timerReducer,
  filterReducer,
  followupReducer,
  callDataReducer,
  callQueueReducer,
  callTimerReducer,
  systemSettingsReducer,
  visitAndDemoReducer,
  renderFieldMarketingUsersTasks,
});

export default rootReducer;

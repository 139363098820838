/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  DashboardIcon,
  VisitedIcon,
  StudentLeadsIcon,
  TodayFollowUpIcon,
  WhatsAppIcon,
  FeesChartIcon,
  ReportIcon,
  SettingsIocn,
  UnsavedIcon,
  FieldMarketingIcon,
} from "./icons";
import { Outlet, useNavigate } from "react-router-dom";
import { $crud } from "../services/CrudFactory";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Collapse } from "@mui/material";
import {
  CallSplit,
  LocalLibrary,
  PersonPinCircle,
  Archive,
  People,
  DoNotDisturbOn,
  EventNote,
} from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import {
  ADMIN_ROLE,
  MANAGER_ROLE,
  TEAM_LEADER_ROLE,
  TELE_CALLER_ROLE,
  SUPER_ADMIN_ROLE,
  FIELD_MARKETING_ROLE
} from "../constants";
import { hasPermission } from "../redux/userReducer";

function SideBar({ locked, setCount, count }) {
  const userRole = useSelector((state) => state.userReducer)?.role?.name;
  const user = useSelector((state) => state.userReducer);
  const userId = localStorage.getItem("user_id");
  const [checked, setChecked] = useState(false);
  const [fieldChecked, setFieldChecked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState(window.location.href.split("/").at(-1));
  
  const navLinks = [
    {
      title: "Dashboard", 
      redirect: "/dashboard",
      icon: <DashboardIcon />,
      access: [SUPER_ADMIN_ROLE,ADMIN_ROLE, TEAM_LEADER_ROLE, TELE_CALLER_ROLE, MANAGER_ROLE, FIELD_MARKETING_ROLE],
    },
    {
      title: "Students Leads",
      redirect: "/leads/lead",
      icon: <StudentLeadsIcon />,
      countKey: "rawLead",
      access: [SUPER_ADMIN_ROLE,ADMIN_ROLE, TEAM_LEADER_ROLE, TELE_CALLER_ROLE, MANAGER_ROLE, FIELD_MARKETING_ROLE],
    },
    {
      title: "Today Follow Up",
      redirect: "/leads/today",
      icon: <TodayFollowUpIcon />,
      countKey: "todayFollowUps",
      access: [SUPER_ADMIN_ROLE,ADMIN_ROLE, TEAM_LEADER_ROLE, TELE_CALLER_ROLE, MANAGER_ROLE, FIELD_MARKETING_ROLE],
    },
    {
      title: "Missed Follow Up",
      redirect: "/leads/missed",
      icon: <TodayFollowUpIcon />,
      countKey: "missedFollowUps",
      access: [SUPER_ADMIN_ROLE,ADMIN_ROLE, TEAM_LEADER_ROLE, TELE_CALLER_ROLE, MANAGER_ROLE, FIELD_MARKETING_ROLE],
    },
    {
      title: "Lead Convert To",
      icon: <VisitedIcon />,
      countKey: "leadConvertTo",
      access: [SUPER_ADMIN_ROLE,ADMIN_ROLE, TEAM_LEADER_ROLE, TELE_CALLER_ROLE, MANAGER_ROLE, FIELD_MARKETING_ROLE],
      subLinks: [
        {
          title: "Lead",
          redirect: "/leads/raw",
          icon: <PersonPinCircle />,
          countKey: "rawLead",
        },
        {
          title: "Visited",
          redirect: "/leads/visited",
          icon: <PersonPinCircle />,
          countKey: "visited",
        },
        {
          title: "Took Demo",
          redirect: "/leads/demoDone",
          icon: <LocalLibrary />,
          countKey: "demo",
        },
        {
          title: "Customer",
          redirect: "/leads/customer",
          icon: <People />,
          countKey: "customer",
        },
        {
          title: "Retargeted",
          redirect: "/leads/retargetting",
          icon: <CallSplit />,
          countKey: "retargeting",
        },
        {
          title: "Do Not Disturb",
          redirect: "/leads/doNotDisturb",
          icon: <DoNotDisturbOn />,
          countKey: "doNotDisturb",
        },
        {
          title: "Archived",
          redirect: "/leads/archived",
          icon: <Archive />,
          countKey: "archived",
        },
      ],
    },

    // {
    //     title: "WhatsApp",
    //     redirect: "/whatsAppForm",
    //     icon: <WhatsAppIcon/>
    // },

    {
      title: "Report",
      redirect: "/report",
      icon: <ReportIcon />,
      access: [SUPER_ADMIN_ROLE, ADMIN_ROLE, TEAM_LEADER_ROLE, TELE_CALLER_ROLE, MANAGER_ROLE],
    },
    {
      title: "Field Marketing",
      icon: <FieldMarketingIcon />,
      access: [SUPER_ADMIN_ROLE,ADMIN_ROLE, TEAM_LEADER_ROLE, MANAGER_ROLE, FIELD_MARKETING_ROLE],
      subLinks: [
        {
          title: "Tracking",
          redirect: "/field-marketing",
          icon: <PersonPinCircle />,
        },
        {
          title: "Task",
          redirect: "/field-marketing/task",
          icon: <PersonPinCircle />,
        },
      ]
    },

    {
      title: "Fees Chart",
      redirect: "/feesChart",
      icon: <FeesChartIcon />,
      access: [SUPER_ADMIN_ROLE,ADMIN_ROLE, TEAM_LEADER_ROLE, TELE_CALLER_ROLE, MANAGER_ROLE, FIELD_MARKETING_ROLE],
    },

    {
      title: "Unsaved",
      redirect: "/unsaved",
      icon: <UnsavedIcon />,
      countKey: "unSavedLead",
      access: [SUPER_ADMIN_ROLE,ADMIN_ROLE, TEAM_LEADER_ROLE, MANAGER_ROLE, FIELD_MARKETING_ROLE],
    },
    {
      title: "UnAssigned",
      redirect: "/unassigned",
      icon: <TodayFollowUpIcon />,
      countKey: "unAssigned",
      access: [SUPER_ADMIN_ROLE,ADMIN_ROLE, TEAM_LEADER_ROLE, MANAGER_ROLE, FIELD_MARKETING_ROLE],
    },
    {
      title: "Scheduler",
      redirect: "/scheduler",
      icon: <EventNote />,
      access: [SUPER_ADMIN_ROLE,ADMIN_ROLE, TEAM_LEADER_ROLE, TELE_CALLER_ROLE, MANAGER_ROLE, FIELD_MARKETING_ROLE],
    },
    {
      title: "Settings",
      redirect: "/settings",
      icon: <SettingsIocn />,
      access: [SUPER_ADMIN_ROLE,ADMIN_ROLE, MANAGER_ROLE],
    },
  ];
  const [showSidebar, setShowSidebar] = useState(false);
  const sidebarRef = useRef();

  const retrieveLeadsCount = async () => {
    try {
      dispatch({
        type: "LOADER",
        loader: true,
      });
      const { data } = await $crud.retrieve("web/leads-count", {
        assignTo: userId,
      });
      return data;
    } catch (e) {
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "error", message: e.message },
      });
      localStorage.clear();
      navigate("/");
    } finally {
      dispatch({
        type: "LOADER",
        loader: false,
      });
    }
  };
  useEffect(() => {
    window.location.href.split("/").at(-1) &&
      setActive(window.location.href.split("/").at(-1));
  }, [window.location.href]);

  useEffect(() => {
    userId && retrieveLeadsCount().then((res) => setCount(res));
  }, [window.location.href]);

  const toggleSidebar = (e) => {
    e.stopPropagation()
    setShowSidebar(!showSidebar);
  };

  const handleOutsideClick = (e) => {
    if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
      setShowSidebar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div className="toggleButton" onClick={toggleSidebar}>
        {showSidebar ? <IoIosArrowBack /> : <IoIosArrowForward />}
      </div>
      <div
        className="hideSidebarDiv"
        style={{ display: !locked ? "block" : "none" }}
      >
        <div className="sidebar" style={{ left: showSidebar ? "0" : "-279px" }} ref={sidebarRef}>
          <div className="logo">CBSL</div>
          <p>Title Name</p>

          <ul style={{ overflowY: "auto" }}>
            {navLinks.map((item, index) => {
              if (item.access.includes(userRole)) {
                return (
                  <React.Fragment key={item?.title}>
                    <li
                      onClick={() => {
                        // setActive(item?.redirect?.split("/").at(-1));
                        item?.title !== "Lead Convert To" && item?.title !== "Field Marketing" &&
                          navigate(item?.redirect);
                        (item?.title === "Lead Convert To" || item?.title === "Field Marketing") &&
                         checked === item?.title ? setChecked(false) : setChecked(item?.title);
                      }}
                      className={
                        active === item?.redirect?.split("/").at(-1)
                          ? "active"
                          : undefined
                      }
                    >
                      <Grid container>
                        <Grid item xs={2}>
                          {item.icon}
                        </Grid>
                        <Grid item xs={7}>
                          {item.title}
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          justifyContent="space-between"
                        >
                          {count && count[`${item?.countKey}`] && (
                            <Chip
                              color="primary"
                              size="small"
                              label={count[`${item?.countKey}`]}
                            />
                          )}
                        </Grid>
                        <Grid item xs={1}>
                          <IoIosArrowForward />
                        </Grid>
                      </Grid>
                    </li>
                    {item?.subLinks && (
                      <Collapse
                        in={
                          (checked === item.title) ||
                          item?.subLinks
                            ?.map((s) => s.redirect?.split("/").at)
                            .includes(active)
                        }
                      >
                        <Grid container>
                          {!!item?.subLinks?.length &&
                            item?.subLinks?.map((sl, index) => {
                              return (
                                <Grid
                                  className={
                                    active === sl?.redirect?.split("/").at(-1)
                                      ? "active"
                                      : undefined
                                  }
                                  item
                                  xs={12}
                                  key={sl?.title}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  onClick={() => {
                                    setActive(sl?.redirect?.split("/").at(-1));
                                    navigate(sl?.redirect);
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={9}
                                    py={1}
                                    pl={5}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    {sl.icon} {sl.title}{" "}
                                  </Grid>
                                  {
                                    <Grid>
                                      <Chip
                                        color="primary"
                                        size="small"
                                        label={
                                          (count && count[`${sl?.countKey}`]) ||
                                          "0"
                                        }
                                      />
                                    </Grid>
                                  }
                                  <Grid
                                    item
                                    xs={3}
                                    display="flex"
                                    justifyContent="center"
                                  >
                                    <IoIosArrowForward />
                                  </Grid>
                                </Grid>
                              );
                            })}
                        </Grid>
                      </Collapse>
                    )}
                  </React.Fragment>
                );
              }
            })}
          </ul>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default SideBar;

export const reRender = (item) => {
  return {
    type: "RERENDER",
    payload: item,
  };
};

export const loader = (item) => {
  return {
    type: "LOADER",
    payload: item,
  };
};
export const snackBar = (item) => {
  return {
    type: "SNACKBAR",
    payload: item,
  };
};
export const detection = (item) => {
  return {
    type: "DETECTION",
    payload: item,
  };
};
export const detection2 = (item) => {
  return {
    type: "DETECTION2",
    payload: item,
  };
};
export const detection3 = (item) => {
  return {
    type: "DETECTION3",
    payload: item,
  };
};
export const renderLead = (item) => {
  return {
    type: "RENDERLEAD",
    payload: item,
  };
};

export const renderFieldMarketingUsersTasks = (item) => {
  return {
    type: "RENDERTASKS",
    payload: item,
  };
};

export const current_user = (item) => {
  return {
    type: "CURRENT USER",
    payload: item,
  };
};

export const users = (item) => {
  return {
    type: "USERS",
    payload: item,
  };
};

export const statuses = (item) => {
  return {
    type: "STATUSES",
    payload: item,
  };
};
export const sources = (item) => {
  return {
    type: "SOURCES",
    payload: item,
  };
};

export const updateSource = (item) => {
  return {
    type: "UPDATESOURCE",
    payload: item,
  };
};
export const courses = (item) => {
  return {
    type: "COURSES",
    payload: item,
  };
};
export const leadConvertTypes = (item) => {
  return {
    type: "LEADCONVERTTYPES",
    payload: item,
  };
};
export const sidebarLocker = (item) => {
  return {
    type: "SIDEBARLOCK",
    payload: item,
  };
};

export const addTimerId = (item) => {
  return {
    type: "TIMER_ID",
    payload: item,
  };
};

export const resumeTimer = (item) => {
  return {
    type: "RESUME_TIMER",
    payload: item,
  };
};

export const pauseTimer = (item) => {
  return {
    type: "PAUSE_TIMER",
    payload: item,
  };
};

export const startTimer = (item) => {
  return {
    type: "START_TIMER",
    payload: item,
  };
};

export const stopTimer = (item) => {
  return {
    type: "STOP_TIMER",
    payload: item,
  };
};

export const addWorkingTime = (item) => {
  return {
    type: "ADD_WORKING_TIME",
    payload: item,
  };
};

export const addBreakTime = (item) => {
  return {
    type: "ADD_BREAK_TIME",
    payload: item,
  };
};

export const addFilterWithValues = (item) => {
  return {
    type: "ADD_FILTER_WITH_VALUES",
    payload: item,
  };
};

export const addFilterWithLabels = (item) => {
  return {
    type: "ADD_FILTER_WITH_LABELS",
    payload: item,
  };
};

export const removeFilter = (item) => {
  return {
    type: "REMOVE_FILTER",
    payload: item,
  };
};
export const addSearchString = (item) => {
  return {
    type: "ADD_SEARCH_STRING",
    payload: item,
  };
};
export const setFollowUpLeadId = (item) => {
  return {
    type: "FOLLOWUP",
    payload: item,
  };
};
export const setFollowUpLead = (item) => {
  return {
    type: "FOLLOWUPLEAD",
    payload: item,
  };
};

export const setFollowUpModalOpen = (item) => {
  return {
    type: "MODALOPEN",
    payload: item,
  };
};

export const setCallData = (item) => {
  return {
    type: "CALLDATA",
    payload: item,
  };
};
export const startCallTimer = () => {
  return {
    type: "STARTCALLTIMER",
    payload: true,
  };
};
export const stopCallTimer = (item) => {
  return {
    type: "STOPCALLTIMER",
    payload: item,
  };
};
export const updateCallTimer = (item) => {
  return {
    type: "UPDATECALLTIMER",
    payload: item,
  };
};
export const setCallLog = (item) => {
  return {
    type: "CALLLOG",
    payload: item,
  };
};
export const setSystemSettings = (item) => {
  return {
    type: "SETSYSTEMSETTINGS",
    payload: item,
  };
};

export const setCustomFilters = (item) => {
  return {
    type: "SET_CUSTOM_FILTERS",
    payload: item,
  };
};

export const addCustomFilter = (item) => {
  return {
    type: "ADD_CUSTOM_FILTER",
    payload: item,
  };
};

export const removeCustomFilter = (item) => {
  return {
    type: "REMOVE_CUSTOM_FILTER",
    payload: item,
  };
};

export const visitAndDemoRetrieve = (item) => {
  return {
    type: "RETRIEVE",
    payload: item,
  };
};

export const changeCallHoldStatus = (item) =>{
  return {
    type: "CALLHOLD",
    payload:item
  };
};
export const changeCallMuteStatus = (item) =>{
  return {
    type: "CALLMUTE",
    payload:item
  };
};
export const changeCallSpeakerStatus = (item) =>{
  return {
    type: "CALLSPEAKER",
    payload:item
  };
};
/* eslint-disable */
import React, {useEffect, useState} from "react";
import {
    Attachment,
    BankAttachment,
    DocumentAttachment,
    ImageAttachment,
    LocationAttachment,
    PersonAttachment,
    SendRightArrow,
    TemplateAttachment,
    TextAttachment,
} from "../../../components/icons";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {
    WhatsAppAccounts,
    WhatsAppContacts,
    WhatsAppDocuments,
    WhatsAppImages,
    WhatsAppLocation,
    WhatsAppTemplates,
    WhatsAppTextTemplates,
} from "./subPages";
import {$crud} from "../../../services/CrudFactory";
import {
    Clear,
    Send,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import {IMAGE_BASE_URL, WEB_API} from "../../../constants";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {WhatsappChatContainer} from "../../../components/WhatsappChatContainer";
import moment from "moment"

export function WhatsAppNew(props) {
    const {mobileNumber, online = false, page} = props;

    const dispatch = useDispatch();
    const actions = [
        {
            icon: <TextAttachment/>,
            name: "Text",
            bg: "linear-gradient(to right,  red, green)",
        },
        {
            icon: <BankAttachment/>,
            name: "Banks",
            bg: "linear-Gradient(blue ,red)",
        },
        {
            icon: <LocationAttachment/>,
            name: "Location",
            bg: "linear-Gradient(blue ,red)",
        },
        {
            icon: <PersonAttachment/>,
            name: "Contact",
            bg: "linear-Gradient(blue ,red)",
        },
        {
            icon: <DocumentAttachment/>,
            name: "Document",
            bg: "linear-Gradient(blue ,red)",
        },
        {
            icon: <ImageAttachment/>,
            name: "Image",
            bg: "linear-Gradient(blue ,red)",
        },
        {
            icon: <TemplateAttachment/>,
            name: "Template",
            bg: "linear-gradient(to right,  red, green)",
        },
    ];

    const initialMessages = [
        {
            content: "hello",
            sender: "You",
            timestamp: "22/04/2024 3:25 PM",
            variant: "sent"
        },
        {
            content: "hi sir",
            sender: "Other",
            timestamp: "22/04/2024 3:26 PM",
            variant: "recieved"
        },
        {
            content: "How May I Help You",
            sender: "You",
            timestamp: "22/04/2024 3:27 PM",
            variant: "sent"
        }
    ]

    const [open, setOpen] = React.useState(false);
    const [messages, setMessages] = useState(initialMessages);
    const [message, setMessage] = useState(null);
    const [whatsappContent, setWhatsappContent] = useState("");
    const [courses, setCourses] = useState([]);
    const user = useSelector((state) => state.userReducer);
    const crmOnlineWhatsAppData = user?.crmWhatsAppAccessKey?.length > 1 ? user.crmWhatsAppAccessKey.split('@') : null;
    let crmWhatsAppNumber;

    const pageContents = {
        "WhatsApp Online": <WhatsappChatContainer messages={messages}/>,
        "WhatsApp": <WhatsAppAccounts setWhatsappContent={setWhatsappContent} setMessage={setMessage} online={online} />,
    }

    const [pageContent, setPageContent] = useState(pageContents[page]);

    const send = encodeURIComponent(whatsappContent);

    const getCourses = async () => {
        try {
            const {
                data: {courses},
            } = await $crud.retrieve("web/course");
            setCourses(courses);
        } catch (error) {
            console.log(error);
        }
    };

    const handleOpen = () => setOpen(true);
    const handleClose = (page) => {
        switch (page) {
            case "Banks" :
                setPageContent(<WhatsAppAccounts setWhatsappContent={setWhatsappContent} setMessage={setMessage} online={online}/>);
                break;
            case "Text":
                setPageContent(<WhatsAppTextTemplates setMessage={setMessage} setWhatsappContent={setWhatsappContent} online={online}/>);
                break;
            case "Contact":
                setPageContent(<WhatsAppContacts setWhatsappContent={setWhatsappContent} setMessage={setMessage} online={online}/>);
                break;
            case "Document":
                setPageContent(
                    <WhatsAppDocuments
                        setWhatsappContent={setWhatsappContent}
                        courses={courses}
                        setMessage={setMessage}
                        online={online}
                    />
                );
                break;
            case "Image":
                setPageContent(
                    <WhatsAppImages
                        setWhatsappContent={setWhatsappContent}
                        courses={courses}
                        setMessage={setMessage}
                        online={online}
                    />
                );
                break;
            case "Template":
                setPageContent(<WhatsAppTemplates online={online}/>);
                break;
            case "Location":
                setPageContent(
                    <WhatsAppLocation
                        setWhatsappContent={setWhatsappContent}
                        setMessage={setMessage}
                        online={online}
                    />
                );
                break;
            default:
                break;
        }
        setOpen(false);
    };

    useEffect(() => {
        setPageContent(pageContents[page])
    }, [messages])
    const handleSubmit = async (mobile) => {

        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.crmWhatsAppAccessKey}`
        };

        let AllMessage = {
            from: `91${crmWhatsAppNumber}`,
            to: mobile,
            message,
            tokenId: `91${crmWhatsAppNumber}`,
            thirdPartyUserId: user?.id,
            thirdPartyUserName: user?.name,
            thirdPartyUserMobileNumber: user?.mobileNo,
            thirdPartyUserProfile: `${IMAGE_BASE_URL}user_profile/${user?.photo}`
        };
        try {
            let {message} = await axios.post(`${WEB_API}/sendMessage`, AllMessage, {headers});
            dispatch({type: "SNACKBAR", snackBar: {type, message}});
        } catch (e) {
            dispatch({type: "SNACKBAR", snackBar: {type: "error", message: e.message}});
        } finally {
            dispatch({type: "LOADER", loader: false});
        }
    };

    useEffect(() => {
        if (!crmOnlineWhatsAppData && page === "WhatsApp Online") {
            dispatch({type: "SNACKBAR", snackBar: {type: "warning", message: "You don't have whatsApp Access key"}})
        } else if (crmOnlineWhatsAppData) {
            crmWhatsAppNumber = crmOnlineWhatsAppData[1]
        }
    }, [])

    useEffect(() => {
        getCourses();
    }, []);

    return (
        <div className="row">
            <div className="col-12">
                <div className="whatsapp-content-container">{pageContent}</div>
            </div>

            <div className="col-12 d-flex custom-message-container mb-3 align-items-center">
                <TextField
                    onChange={(e) => {
                        setMessage(e.target.value);

                        setWhatsappContent(e.target.value);
                    }}
                    placeholder="Type a message.."
                    value={message || ""}
                    fullWidth
                    size="small"
                    InputProps={{
                        style: {
                            borderRadius: "20px",
                        },
                    }}
                    multiline
                    rows={3}
                    maxRows={10}
                />
                {message && (
                    <div className="msg-send-btn">
                        <Clear
                            sx={{cursor: "pointer",}}
                            onClick={() => {
                                setMessage("");
                                setWhatsappContent("");
                            }}
                        />
                    </div>
                )}
                <div style={{marginLeft: "5px", marginRight: "5px", width: "40px", height: "40px"}}>
                    <Box sx={{transform: "translateZ(0px)", flexGrow: 1}}>
                        <SpeedDial
                            ariaLabel="SpeedDial controlled open example"
                            sx={{
                                position: "absolute",
                                bottom: -40,
                                right: -18,
                                "& .MuiFab-primary": {
                                    width: 40,
                                    height: 40,
                                    background: "transparent",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    "&:hover": {background: "transparent"},
                                },
                            }}
                            icon={
                                <span className="attachments-toggler">
                  <Attachment/>
                </span>
                            }
                            onClick={!open ? handleOpen : handleClose}
                            open={open}
                        >
                            {actions.map((action) => (
                                (action?.name === "Template" && page === "WhatsApp") ? null : <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    sx={{
                                        background: "transparent",
                                        width: 50,
                                        height: 50,
                                        marginTop: "-10px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        boxShadow: "none",
                                        "&:hover": {background: "transparent"},
                                    }}
                                    onClick={() => handleClose(action?.name)}
                                />

                            ))}
                        </SpeedDial>
                    </Box>
                </div>
                {page === "WhatsApp Online" && <IconButton
                    className="msg-send-btn"
                    onClick={() => {
                        message && setMessages(prev => {
                            return [...prev, {
                                content: message,
                                variant: "sent",
                                timestamp: moment().format("DD/MM/YYYY LT"),
                                sender: "You"
                            }]
                        })
                        // handleSubmit(mobileNumber);
                    }}
                >
                    <Send/>
                </IconButton>
                }
                {page === "WhatsApp" && <div className="msg-send-btn">
                    <a href={`https://wa.me/${mobileNumber}?text=${send}`} target="_blank"
                       style={{textDecoration: "none"}}><SendRightArrow/></a>
                </div>}
            </div>
        </div>
    );
}

/* eslint-disable */
import React, {useCallback, useEffect, useState} from "react";
import {
    Grid,
    TextField,
    MenuItem,
    Avatar,
    Typography,
    Select,
    Box,
    Chip,
    OutlinedInput,
    Button,
    DialogContent,
    Checkbox,
    SwipeableDrawer,
    FormControlLabel,
    InputAdornment,
    InputLabel,
    Autocomplete
} from "@mui/material";
import {useDataParams} from "../hooks/useDataParams";
import {$crud} from "../services/CrudFactory";
import {useDispatch, useSelector} from "react-redux";
import {renderLead, users} from "../redux/actions";
import _ from "lodash";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {EMPLOYEE_IMAGE_BASE_URL} from "../constants";
import {retrieveUsers} from "../pages";
import moment from "moment";

export default function LeadForm(props) {
    const [lead_id, setLead_Id] = useState(null);
    const [interestedInIds, setInterestedInIds] = useState([]);
    const [branches, setBranches] = useState([]);
    const [leadCategories, setLeadCategories] = useState([]);
    const [degrees, setDegrees] = useState([]);
    const [universities, setUniversities] = useState([]);
    const [colleges, setColleges] = useState([]);
    const [tags, setTags] = useState([]);
    const [mobileCheckRes, setMobileCheckRes] = useState({
        mobile1: {type: "", message: ""},
        mobile2: {type: "", message: ""}
    });
    const {leadId, onClose, onOpen, onNew} = props;
    const dispatch = useDispatch();
    const {params, setParams, handleChange} = useDataParams();
    const user = useSelector(state => state.userReducer);
    const renderState = useSelector((state) => state.renderLead.render1);
    const statuses = useSelector((state) => state.statusReducer).filter(s => !!s.isActive);
    const sources = useSelector((state) => state.sourcesReducer).filter(s => !!s.isActive);
    const courses = useSelector((state) => state.courseReducer);
    const employeesData = useSelector((state) => state.usersReducer);
    const snackbar = useSelector(state => state.newReducer).snackBar || {type: "", message: ""};
    const retrieveData = async (url) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {data} = await $crud.retrieve(url);
            return data;
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }

    const createLeadForm = async (isOpen = false) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {type, message, data: {lead}} = await $crud.post(
                !lead_id ? "create/web/lead" : "update/web/lead",
                {
                    ...params,
                    schedule: params?.schedule ? moment(params.schedule).format("YYYY-MM-DD HH:MM:SS") : null,
                    interestedInIds,
                    crmLead: "Yes",
                    // sourceIp: window.location.host,  `
                }
            );
            isOpen && onOpen(lead?.id)
            dispatch(renderLead({render1: !renderState}));
            retrieveUsers().then(res => dispatch(users(res?.users)))
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });


        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };
    const get_Pincode = (pin) => {
        var requestOptions = {
            method: "GET",
            redirect: "follow",
        };
        fetch(
            `https://api.postalpincode.in/pincode/${pin}/`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result[0].Status !== "Error") {
                    setParams((prev) => {
                        return {...prev, city: result[0]?.PostOffice[0]?.District};
                    });
                    setParams((prev) => {
                        return {...prev, state: result[0]?.PostOffice[0]?.State};
                    });
                    setParams((prev) => {
                        return {...prev, country: result[0]?.PostOffice[0]?.Country};
                    });
                }
            })
            .catch((error) => console.error("error", error));
    }
    const debounced_get_pincode = useCallback(_.debounce(get_Pincode, 500), [])

    const retrieveLead = async () => {
        const {data: {lead}} = await $crud.retrieve("web/lead", {id: leadId});
        return lead;
    }

    const checkMobilePattern = (phone) => {
        const pattern = /^[6-9][0-9]{9}$/
        if (phone) {
            return pattern.test(phone);
        } else {
            return false;
        }
    }

    const checkMobileExist = async (key, mobile) => {
        if (!checkMobilePattern(mobile)) {
            setMobileCheckRes(prev => {
                return {...prev, [key]: {type: "error", message: "Enter Valid Phone Number"}}
            })
            return;
        }
        try {
            dispatch({
                type: "LOADER",
                loader: true
            });
            const {type, message} = await $crud.post("retrieve/web/check-exist-mobile", {mobile});
            setMobileCheckRes(prev => {
                return {...prev, [key]: {type, message}}
            })

        } catch (e) {
            setMobileCheckRes(prev => {
                return {...prev, [key]: {type: "error", message: e.message}}
            })
        } finally {
            dispatch({
                type: "LOADER",
                loader: false
            });
        }
    }

    useEffect(() => {
        leadId && setLead_Id(leadId)
    }, [props?.open])

    useEffect(() => {

        if (lead_id) retrieveLead().then(res => {
            setParams({...params, ...res})
            setInterestedInIds(res?.interestedIn?.map(i => i?.course?.id))
        })
    }, [lead_id])

    useEffect(() => {
        if (!!params?.pincode) debounced_get_pincode(params?.pincode);
    }, [params?.pincode]);

    // useEffect(() => {
    //     props?.open &&
    //
    // }, [props?.open])

    useEffect(() => {
        user?.id && setParams({...params, branchId: user?.branchId, assignTo: user?.id})
    }, [user, props?.open])

    useEffect(() => {
        const time = setTimeout(() => {
            setMobileCheckRes({
                mobile1: {type: "", message: ""},
                mobile2: {type: "", message: ""}
            })
        }, 5000)
        return () => clearTimeout(time);
    }, [mobileCheckRes?.mobile1?.message, mobileCheckRes?.mobile2?.message]);
    
    useEffect(()=>{
        props?.open && (async () => {
            const [branches, tags, colleges, degreeAndDiplomas, boardAndUniversities, leadTypes] = await Promise.all([
                retrieveData("web/branches"),
                retrieveData("web/tags"),
                retrieveData("web/colleges"),
                retrieveData("web/degree-and-diplomas"),
                retrieveData("web/board-and-universities"),
                retrieveData("web/lead-types"),
            ])
            setBranches(branches.branches)
            setDegrees(degreeAndDiplomas.degreeAndDiplomas)
            setUniversities(boardAndUniversities.boardAndUniversities)
            setColleges(colleges.colleges)
            setLeadCategories(leadTypes.leadTypes)
            setTags(tags.tags)
        })()
    },[props?.open]);


    return (
        <SwipeableDrawer
            anchor={props?.anchor}
            open={props?.open}
            onOpen={() => { 
               
            }}
            onClose={() => {
                onClose();
                setParams({});
                setInterestedInIds([]);
            }}
        >
            <Grid className="p-3 leadForm">
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Choose Branch</InputLabel>
                        </Grid>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            value={`${params?.branchId}` || ""}
                            onChange={(e) => handleChange("branchId", e.target.value)}
                            sx={{
                                svg: {color: "#fff"}
                            }}
                        >
                            {branches.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Choose Status</InputLabel>
                        </Grid>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            value={`${params?.statusId}` || ""}
                            onChange={(e) => handleChange("statusId", e.target.value)}
                            sx={{
                                svg: {color: "#fff"}
                            }}
                        >
                            {statuses.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Choose Source</InputLabel>
                        </Grid>
                        <TextField
                            select
                            fullWidth
                            size="small"
                            value={`${params?.sourceId}` || ""}
                            onChange={(e) => handleChange("sourceId", e.target.value)}
                            sx={{
                                svg: {color: "#fff"}
                            }}
                        >
                            {sources.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Choose Assign To</InputLabel>
                        </Grid>
                        <TextField
                            fullWidth
                            size="small"
                            select
                            variant="outlined"
                            value={`${params?.assignTo}` || ""}
                            color="primary"
                            onChange={(e) => handleChange("assignTo", e.target.value)}
                            sx={{
                                svg: {color: "#fff"},
                                "& .MuiSelect-select": {
                                    padding: "0px"
                                }
                            }}

                            InputProps={{
                                style: {padding: "0", display: "flex"},

                            }}


                        >
                            {employeesData?.map((employee, index) => (

                                <MenuItem key={index} value={employee?.id}>
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item xs>
                                            <Avatar
                                                variant="square"
                                                src={EMPLOYEE_IMAGE_BASE_URL + employee?.photo}
                                            />
                                        </Grid>
                                        <Grid item xs>{employee?.name}</Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            style={{fontSize: "16px", fontWeight: 500}}
                        >
                            Lead Form
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Name</InputLabel>
                        </Grid>
                        <TextField
                            value={params?.name || ""}
                            onChange={(e) => handleChange("name", e.target.value)}
                            placeholder="Enter Name"
                            fullWidth
                            size="small"
                        />

                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Father's Name</InputLabel>
                        </Grid>
                        <TextField
                            value={params?.fatherName || ""}
                            onChange={(e) => handleChange("fatherName", e.target.value
                            )}
                            placeholder="Enter Father's Name"
                            fullWidth
                            size="small"
                        />

                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center"
                              sx={{margin: "-10px 0px -5px 0"}}>
                            <InputLabel>Mobile 1</InputLabel>
                            <FormControlLabel sx={{marginRight: "0px"}} control={<><WhatsAppIcon
                                color={params?.mobile1IsWhatsapp ? "secondary" : "inherit"}/><Checkbox
                                checked={!!params?.mobile1IsWhatsapp}
                                sx={{
                                    svg: {color: "#FFF"}
                                }}
                                onChange={(e) => handleChange("mobile1IsWhatsapp", e.target.checked)}/></>}/>
                        </Grid>
                        <TextField
                            value={params?.mobile1 || ""}
                            onChange={(e) => handleChange("mobile1", e.target.value)}
                            onBlur={() => checkMobileExist("mobile1", params?.mobile1)}
                            placeholder="Enter Mobile No.1"
                            fullWidth
                            size="small"
                            // type="tel"
                            // inputProps={{
                            //     pattern:"^[7-9][0-9]{9}$"
                            // }}
                            helperText={mobileCheckRes?.mobile1?.message}
                            error={mobileCheckRes?.mobile1?.type === "error" ? true : false}
                        />

                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center"
                              sx={{margin: "-10px 0px -5px 0"}}>
                            <InputLabel>Mobile 2</InputLabel>
                            <FormControlLabel sx={{marginRight: "0px"}} control={<><WhatsAppIcon
                                color={params?.mobile2IsWhatsapp ? "secondary" : "inherit"}/><Checkbox
                                checked={!!params?.mobile2IsWhatsapp}
                                sx={{
                                    svg: {color: "#FFF"}
                                }}
                                onChange={(e) => handleChange("mobile2IsWhatsapp", e.target.checked)}/></>}/>
                        </Grid>
                        <TextField
                            value={params?.mobile2 || ""}
                            onBlur={() => checkMobileExist("mobile2", params?.mobile2)}
                            onChange={(e) => handleChange("mobile2", e.target.value
                            )}
                            placeholder="Enter Mobile No.2"
                            fullWidth
                            size="small"
                            helperText={mobileCheckRes?.mobile2?.message}
                            error={mobileCheckRes?.mobile2?.type === "error" ? true : false}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Pincode</InputLabel>
                        </Grid>
                        <TextField
                            value={params?.pincode || ""}
                            onChange={(e) => handleChange("pincode", e.target.value
                            )}
                            placeholder="Enter Pincode"
                            fullWidth
                            size="small"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">City</InputLabel>
                        </Grid>
                        <TextField
                            value={params?.city || ""}
                            onChange={(e) => handleChange("city", e.target.value
                            )}
                            placeholder="Enter City"
                            fullWidth
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">State</InputLabel>
                        </Grid>
                        <TextField
                            value={params?.state || ""}
                            onChange={(e) => handleChange("state", e.target.value
                            )}
                            placeholder="Enter State"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Country</InputLabel>
                        </Grid>
                        <TextField
                            value={params?.country || ""}
                            onChange={(e) => handleChange("country", e.target.value
                            )}
                            placeholder="Enter Country"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Email</InputLabel>
                        </Grid>
                        <TextField
                            value={params?.email || ""}
                            onChange={(e) => handleChange("email", e.target.value
                            )}
                            placeholder="Enter Email"
                            fullWidth
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">DOB</InputLabel>
                        </Grid>
                        <TextField
                            size="small"
                            value={params?.dob || ""}
                            onChange={(e) => handleChange("dob", e.target.value)}
                            InputLabelProps={{shrink: true}}
                            fullWidth
                            type="date"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Address</InputLabel>
                        </Grid>
                        <TextField
                            value={params?.address || ""}
                            onChange={(e) => handleChange("address", e.target.value
                            )}
                            placeholder="Enter Address"
                            fullWidth
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Degree/Diploma</InputLabel>
                        </Grid>
                        <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            size="small"
                            disableClearable
                            value={params?.degree || {name: ""}}
                            onChange={(event, newValue) => {
                                if (typeof newValue !== "string") {
                                    handleChange("degreeId", newValue.id);
                                    handleChange("degree", newValue)
                                }
                            }}
                            options={degrees}
                            getOptionLabel={(option) => option.name}

                            renderOption={(props, option) => {
                                return <Grid {...props}>{option?.name}</Grid>
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(e) => {
                                        handleChange("degreeId", e.target.value)
                                        handleChange("degree", {name: e.target.value})
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">College/Institute</InputLabel>
                        </Grid>
                        <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            size="small"
                            disableClearable
                            value={params?.college || {name: ""}}
                            onChange={(event, newValue) => {
                                if (typeof newValue !== "string") {
                                    handleChange("collegeId", newValue.id);
                                    handleChange("college", newValue)
                                }
                            }}
                            options={colleges}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => {
                                return <Grid {...props}>{option?.name}</Grid>
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(e) => {
                                        handleChange("collegeId", e.target.value)
                                        handleChange("college", {name: e.target.value})
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">University</InputLabel>
                        </Grid>
                        <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            size="small"
                            disableClearable
                            value={params?.university || {name: ""}}
                            onChange={(event, newValue) => {
                                if (typeof newValue !== "string") {
                                    handleChange("universityId", newValue.id);
                                    handleChange("university", newValue)
                                }
                            }}
                            options={universities}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => {
                                return <Grid {...props}>{option?.name}</Grid>
                            }}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(e) => {
                                        handleChange("universityId", e.target.value)
                                        handleChange("university", {name: e.target.value})
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Year Of Passing</InputLabel>
                        </Grid>
                        <TextField
                            value={params?.yearOfPassing || ""}
                            onChange={(e) => {
                                if (!isNaN(e.target.value)) {
                                    if (e.target.value.length <= 4) {
                                        handleChange("yearOfPassing", e.target.value);
                                    }
                                }
                            }}
                            placeholder="Enter Year Of Passing"
                            fullWidth
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Company</InputLabel>
                        </Grid>
                        <TextField
                            value={params?.company || ""}
                            onChange={(e) => handleChange("company", e.target.value)}
                            placeholder="Enter Company"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Job Profile</InputLabel>
                        </Grid>
                        <TextField
                            value={params?.jobProfile || ""}
                            onChange={(e) => handleChange("jobProfile", e.target.value)}
                            placeholder="Enter Job Profile"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Attended By</InputLabel>
                        </Grid>

                        <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            size="small"
                            disableClearable
                            value={params?.AttendedBy || {name: ""}}
                            onChange={(event, newValue) => {
                                if (typeof newValue !== "string") {
                                    handleChange("attendedBy", newValue.id);
                                    handleChange("AttendedBy", newValue)
                                }
                            }}
                            options={employeesData}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => {
                                return <Grid {...props}>{option?.name}</Grid>
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(e) => {
                                        handleChange("attendedBy", e.target.value)
                                        handleChange("AttendedBy", {name: e.target.value})
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Lead Value</InputLabel>
                        </Grid>
                        <TextField
                            value={params?.leadValue || ""}
                            onChange={(e) => handleChange("leadValue", e.target.value)}
                            placeholder="Enter Lead Value"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Lead Category</InputLabel>
                        </Grid>
                        <TextField
                            fullWidth
                            size="small"
                            select
                            variant="outlined"
                            value={`${params?.leadTypeId}` || ""}
                            color="primary"
                            onChange={(e) => handleChange("leadTypeId", e.target.value)}
                        >
                            {leadCategories?.map((category) => (
                                <MenuItem key={category?.id} value={category?.id}>{category?.name}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item sm={9}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Courses</InputLabel>
                        </Grid>
                        <Select
                            multiple
                            color="primary"
                            fullWidth
                            value={interestedInIds || []}
                            input={<OutlinedInput id="select-multiple-chip"/>}
                            size="small"
                            onChange={(e) => {
                                const {value} = e.target;
                                setInterestedInIds(value)
                            }}
                            renderValue={(selected) => (<Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                                    {selected?.map((value) => (
                                        <Chip
                                            color="primary"
                                            size="small"
                                            key={value}
                                            label={courses.filter(course => value === course?.id).map(c => c.name)}
                                        />
                                    ))}
                                </Box>
                            )

                            }
                        >
                            {courses?.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item.id}
                                >
                                    <Checkbox
                                        checked={!!interestedInIds?.length && !!interestedInIds?.find(i => i == item?.id)}
                                    />

                                    {item?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item sm={12}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <InputLabel className="mb-1">Tags</InputLabel>
                        </Grid>
                        <Autocomplete
                            multiple
                            id="tags-filled"
                            options={tags}
                            // defaultValue={[[{id:1,title:"option1"},{id:1,title:"option2"}][1].title]}
                            onChange={(e, newValue) => {
                                setParams((prev) => {
                                    return {...prev, tags: [...(newValue.map(v => v.id || v))]};
                                })
                            }}
                            freeSolo
                            renderOption={(props, option) => {
                                return <Grid {...props}>{option.name}</Grid>
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" size="small"
                                          label={option.name || option} {...getTagProps({index})} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                    placeholder="Tags"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item xs className="d-flex justify-content-end align-items-center mt-3">
                    <Button
                        variant="outlined"
                        onClick={() => {
                            onClose();
                            setInterestedInIds([])
                            setLead_Id(null)
                        }}
                        color="primary"

                    >
                        Cancel
                    </Button>
                    <Button
                        className="ms-2"
                        onClick={() => {
                            createLeadForm();
                            onClose();
                            setParams({})
                            setLead_Id(null)
                            setInterestedInIds([])
                        }}
                        variant="contained"
                        color="primary"

                    >
                        Save And Close
                    </Button>
                    <Button
                        className="ms-2"
                        onClick={() => {
                            createLeadForm();
                            setParams({assignTo: user?.id, branchId: user?.branchId})
                            setInterestedInIds([])
                            setLead_Id(null)
                        }}
                        variant="contained"
                        color="primary"

                    >
                        Save And New
                    </Button>
                    <Button
                        className="ms-2"
                        onClick={() => {
                            onClose()
                            createLeadForm(true);
                            setLead_Id(null)
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Save And Open
                    </Button>
                </Grid>
            </Grid>
        </SwipeableDrawer>
    );
}

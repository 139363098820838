/* eslint-disable */
import React, {useEffect, useState, useRef} from "react";
import {ChatBox, LeadDetailField} from "../../../components";
import {CustomSelect} from "../../../components/inputs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Calendar} from 'rsuite';
import dayjs from "dayjs";
import {
    Grid,
    TextField,
    Chip,
    InputLabel,
    FormControl,
    OutlinedInput,
    InputAdornment,
    IconButton,
    FormHelperText,
    Select,
    MenuItem,
    Checkbox,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    Badge
} from "@mui/material";
import {DateTimePicker} from '@mui/x-date-pickers';
import {useDataParams} from "../../../hooks/useDataParams";
import {$crud} from "../../../services/CrudFactory";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {renderLead, setFollowUpLeadId} from "../../../redux/actions";
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Save,
    Edit,
    Visibility,
    PhoneMissed,
    PhoneDisabled,
    PhoneCallback,
    PhoneForwardedSharp, NoteAltSharp, CalendarMonth, Add, AddRounded, AddCircleOutline
} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {maskMobile} from "../../../features/Features";
import { LeadTagsModal } from "../../LeadTagsModal";

export function NotesFollowUps({lead, setLead, viewCount, onSubmit}) {
    const followupLeadId = useSelector(state => state.followupReducer);
    const options = ["Not Reachable", "Not Picked", "Answered", "Rejected"];
    const dispatch = useDispatch();
    const [date, setDate] = useState(dayjs(new Date()));
    const [time, setTime] = useState(dayjs(new Date()));
    const [selectDate, setSelectDate] = useState(null);
    const [leadsAndFollowsUp, setLeadsAndFollowsUp] = useState([]);
    const [interestedInIds, setInterestedInIds] = useState([]);
    const [readOnly, setReadOnly] = useState(false);
    const {handleChange, params, setParams} = useDataParams();
    const [render, setRender] = useState(null);
    const [statusLog, setStatusLog] = useState(null);
    const [selectedOption, setSelectedOption] = useState("Schedule a remainder");
    const renderState = useSelector((state) => state.renderLead.render1);
    const currentUser = useSelector((state) => state.userReducer);
    const courses = useSelector(state => state.courseReducer);
    const [loading, setLoading] = React.useState(false);
    const [validParams, setValidParams] = useState({});
    const [dateDisable, setDateDisable] = useState([]);
    const inputRef = useRef(null);
    const [leadTag, setleadTag] = useState(false);
    const [openCalendar, setOpenCalendar] = useState(false);
    // console.log(lead, "-----lead");
    // console.log(leadsAndFollowsUp, "-----------leadsAndFollowsUp");

    const onClose = ()=>{
        setleadTag(false);
    }

    const createNotesFollowUps = async () => {
        try {
            setLoading(true);
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {type, message} = await $crud.post(
                "create/web/add/notes-follows-up",
                {
                    ...validParams,
                    date: moment(`${date}`).format("YYYY-MM-DD HH:mm:ss"),
                    time,
                    leadId: lead?.id,
                    userId: currentUser?.id,
                    sourceIp: window.location.host,
                    // scheduleRemainder: scheduleRemainder ? 1 : 0,
                    scheduleRemainder: selectedOption == "Schedule a remainder" ? 1 : 0,
                }
            );
            const {type: leadUpdateResponseType, message: leadUpdateResponseMessage} = await $crud.post(
                "update/web/lead",
                {
                    // ...params,
                    id: lead?.id,
                    globalNote: params?.globalNote,
                    schedule: selectedOption === "Schedule a remainder" ? moment(`${date}`).format("YYYY-MM-DD HH:mm:ss") : null,
                    interestedInIds: interestedInIds.map(i => i.id)
                }
            )
            // dispatch(renderLead({render1: !renderState}));


            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
            onSubmit();
            setInterestedInIds([]);
            // setParams({});
            // setLead({});
            // setSelectDate(null);
            setLoading(false)
            setDate(new Date());
            setTime(new Date());
            updateMissedFollowUp();
        } catch (e) {
            const {message} = e;

            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
            setLoading(false)
        }
    };
    const retrieveNotesFollowUp = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                data: {lead_notes_follow_up},
            } = await $crud.retrieve("web/add/notes-follows-up", {
                leadId: lead?.lead ? lead?.lead?.id : lead?.id,
            });
            setLeadsAndFollowsUp(lead_notes_follow_up);
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };

    // const retrieveLeadTags = async ()=>{
    //     try {
    //         const {data} = await $crud.retrieve("web/lead-tags", {})
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const addDay = (day) => {
        return moment(new Date(), "DD-MM-YYYY").add(day, "days");
    };

    const shouldDisableDate = (day) => {
        const matchingDates = dateDisable.filter(item => item.date === dayjs(day).format("YYYY-MM-DD") && item.count >= currentUser?.limit);
        const sundays = dayjs(day).get("day");
        return (!Boolean(sundays) || matchingDates.length > 0);
    };

    function getParticularDateFollowUps(date) {
        const day = moment(date).format("YYYY-MM-DD");
        const foundObj = dateDisable?.find(d => moment(d.date).isSame(moment(day)));

        return foundObj;
    }

    // const renderDateCell = (date, currentDate, DayComponentProps) => {
    //     const item = getParticularDateFollowUps(DayComponentProps?.key);
    //     if (item) {
    //         return (
    //           <Badge badgeContent={item?.count} color="primary">
    //             {/* {date.getDate()} */}
    //           </Badge>
    //         );
    //       } else {
    //         return <Grid container>
    //             <Grid item sx={{display:"flex"}}>

    //             {moment(DayComponentProps?.key).format("D")}
    //             </Grid>
    //             </Grid>;
    //       }
    //   };


    function renderCell(date) {
        const item = getParticularDateFollowUps(date);
        
        return (
            <Badge className="calendar-todo-list" badgeContent={item?.count} color="success">
            </Badge>
        );
    }

    const updateMissedFollowUp = async () => {
        try {
            setRender(false);
            await $crud.put("update/web/missed-follows-up", {
                id: leadsAndFollowsUp[0]?.id,
            });
            setRender(true);
        } catch (error) {
            console.error(error);
        }
    };

    const retrieveStatusLog = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                data: {assignLead},
            } = await $crud.retrieve("/web/assign-lead", {leadId: lead?.id});
            setStatusLog(assignLead);
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };
    const followupDisableDate = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {data:{allDatesWithTimes}} = await $crud.retrieve("/web/followup-disable");
            setDateDisable(allDatesWithTimes);
            // console.log(allDatesWithTimes, "-------allDatesWithTimes");
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };
    useEffect(()=>{
       lead && followupDisableDate();
    }, [])

    useEffect(() => {
        Object.keys(params).map(key => {
            if (params[key]) {
                setValidParams({...validParams, [key]: params[key]})
            }
        })
    }, [params])
    useEffect(() => {
        setDate(new Date());
        if (lead?.id) {
            retrieveNotesFollowUp();
            retrieveStatusLog();
            setParams(lead)
            // !!lead?.interestedIn.length && setInterestedInIds(lead?.interestedIn?.map(i => i.course))
        }
    }, [render, lead?.id, renderState]);

    let maxIndex = leadsAndFollowsUp?.reduce((maxIndex, obj, currentIndex) => {
        return obj.id > leadsAndFollowsUp[maxIndex].id ? currentIndex : maxIndex;
    }, 0);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <div className="row">
            <Grid container px={1}>
                <Grid item xs={2} display="flex" alignItems="center">
                    <Tooltip title="Total Views">
                        <Visibility/>
                    </Tooltip>
                    <Typography varirant="caption" sx={{ml: 2}}>{viewCount}</Typography>
                </Grid>
                <Grid item xs={2} display="flex" alignItems="center">
                    <Tooltip title="Total Incoming Calls">
                        <PhoneCallback/>
                    </Tooltip>
                    <Typography varirant="caption" sx={{ml: 2}}>0</Typography>
                </Grid>
                <Grid item xs={2} display="flex" alignItems="center">
                    <Tooltip title="Total Calls Rejected By Lead">
                        <PhoneDisabled/>
                    </Tooltip>
                    <Typography varirant="caption" sx={{ml: 2}}>0</Typography>
                </Grid>
                <Grid item xs={2} display="flex" alignItems="center">
                    <Tooltip title="Total Outgoing Calls">
                        <PhoneForwardedSharp/>
                    </Tooltip>
                    <Typography varirant="caption" sx={{ml: 2}}>0</Typography>
                </Grid>
                <Grid item xs={2} display="flex" alignItems="center">
                    <Tooltip title="Total Missed Calls">
                        <PhoneMissed/>
                    </Tooltip>
                    <Typography varirant="caption" sx={{ml: 2}}>0</Typography>
                </Grid>
                <Grid item xs={2} display="flex" alignItems="center">
                    <Tooltip title="Total FollowUps">
                        <NoteAltSharp/>
                    </Tooltip>
                    <Typography varirant="caption" sx={{ml: 2}}>{leadsAndFollowsUp?.length}</Typography>
                </Grid>
            </Grid>

            <div className="col-xl-4 col-lg-6 col-md-12 mt-4">
                <div className="row">

                    <div className="col-12 ">
                        <div className="last-response-container position-relative">
                            <LeadDetailField
                                title={
                                    leadsAndFollowsUp?.length >= 1
                                        ? `Last Response:   ${moment(
                                        leadsAndFollowsUp[maxIndex]?.createdAt
                                        ).format("DD-MMM-YY  | LT ")}`
                                        : "Last Response: N/A"
                                }
                                desc={
                                    leadsAndFollowsUp
                                        ? leadsAndFollowsUp[maxIndex]?.note
                                        : "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested."
                                }
                                className={"mb-2"}
                            />
                            <hr className="mb-2" style={{height: "1px"}}/>
                            <div className="d-flex justify-content-end w-100">
                                <button className="last-response-btn mx-2">
                                    Total FollowUps: {!!leadsAndFollowsUp?.length ?
                                    <Chip

                                        sx={{
                                            fontSize: "12px",
                                            height: "auto",
                                            marginLeft: "5px"
                                        }}
                                        label={leadsAndFollowsUp?.length}
                                    /> : "0"}
                                </button>
                                <button className="last-response-btn">
                                    Next Response: {!!lead?.schedule ?
                                    <Chip

                                        sx={{
                                            fontSize: "12px",
                                            height: "auto",
                                            marginLeft: "5px"
                                        }}
                                        label={moment(`${lead?.schedule}`).format("DD-MMM-YYYY") + " | " + moment(`${lead?.schedule}`).format("LT")}
                                    /> : "N/A"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <Grid container>
                        <Grid item xs={12} mt={2}>
                            <InputLabel>Interested In (Courses)</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                multiple
                                color="primary"
                                fullWidth
                                value={interestedInIds}
                                input={<OutlinedInput id="select-multiple-chip"/>}
                                size="small"
                                onChange={(e) => {
                                    const {value} = e.target;
                                    setInterestedInIds(value)
                                }}
                                renderValue={(selected) => (<Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                                        {selected?.map((value) => (
                                            <Chip
                                                color="primary"
                                                size="small"
                                                key={value?.id}
                                                label={value?.name}
                                            />
                                        ))}
                                    </Box>
                                )

                                }
                            >
                                {courses?.map((item, index) => (
                                    <MenuItem
                                        key={item?.name}
                                        value={item}
                                    >
                                        <Checkbox
                                            checked={!!interestedInIds?.length && interestedInIds?.find(i => i?.id == item?.id)}
                                        />

                                        {item?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                    <div>
                        <div className="col-12 my-2">
                            <div className="remainder w-100">
                                <div className="title">Reminder</div>

                                <div className="remainder-optionss d-flex ">
                                    <label className="me-2 pe-2">
                                        <input
                                            className="me-2"
                                            type="radio"
                                            value="No remainder"
                                            checked={selectedOption === "No remainder"}
                                            onChange={handleOptionChange}
                                            style={{accentColor: "#9747FF"}}
                                        />
                                        No reminder
                                    </label>
                                    <label>
                                        <input
                                            className="me-2"
                                            type="radio"
                                            value="Schedule a remainder"
                                            checked={selectedOption === "Schedule a remainder"}
                                            onChange={handleOptionChange}
                                            style={{accentColor: "#9747FF"}}
                                        />
                                        Schedule a reminder
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {selectedOption == "Schedule a remainder" && (
                        <>
                            <div className="col-12">
                                <div className="next-schedule-shortcut-btns w-100">
                                    {
                                        [
                                            {day: 0, title: "Today"},
                                        ].map(({day, title}, index) => (
                                            <button
                                                key={day}
                                                className="shortcut-btn"
                                                onClick={() => {
                                                    setSelectDate(index);
                                                    index === 0 ? setDate(new Date()) : setDate(addDay(day));
                                                }}
                                                style={{backgroundColor: selectDate === index && "#9747ff"}}
                                            >
                                                {title}
                                            </button>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="col-11">
                                <InputLabel>
                                    Schedule Date & Time
                                </InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs} sx={{width: "100%"}}>
                                {/* <Badge badgeContent={leadsAndFollowsUp?.length} color="success"> */}
                                    <DateTimePicker
                                        // label="Schedule Date & Time"
                                        minDate={new Date()}
                                        value={date}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}
                                        // dayOfWeekFormatter={(date)=>date.toUpperCase()}
                                        // showToolbar={true}
                                        // OpenPickerButtonProps={{
                                        //     children:"1"
                                        // }}
                                        // DialogProps={{
                                        //     children:
                                        // }}

                                        // renderDay={(date, currentDate, DayComponentProps) =>
                                        //     renderDateCell(date, currentDate, DayComponentProps)
                                        // }

                                        shouldDisableDate={shouldDisableDate}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                color="primary" {...params}
                                                sx={{
                                                    svg: {color: theme => theme.palette.primary.main}
                                                }}
                                            />
                                        )}
                                    />
                                    {/* </Badge> */}
                                </LocalizationProvider>
                                {/* <div style={{width:"100%", display:"flex", justifyContent:"space-between", padding:"4px 4px", border:'1px solid white'}}>
                                <Typography>{date ? moment(`${date}`).format("YYYY-MM-DD"): null}</Typography>
                                <CalendarMonth onClick={()=>setOpenCalendar(true)}/>
                                </div>
                            <Dialog open = {openCalendar}>
                                <DialogContent>
                                    <Calendar 
                                        compact
                                        bordered
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}
                                        renderCell={renderCell}
                                        disabledDate={shouldDisableDate}
                                        />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={()=>setOpenCalendar(false)} variant="contained">Cancel</Button>
                                </DialogActions>
                            </Dialog> */}
                            </div>
                            <div className="col-1" style={{display:"flex",justifyContent:"center", alignItems:"center"}} >
                            <CalendarMonth  onClick={()=>setOpenCalendar(true)} sx={{mt:3, fontSize:"35px"}} />
                            <Dialog open = {openCalendar}  >
                                <DialogContent sx={calendarStyles}>
                                    <Calendar 
                                        style={calendarStyles}
                                        compact
                                        bordered
                                        readOnly
                                        renderCell={renderCell}
                                        disabledDate={shouldDisableDate}
                                        />
                                </DialogContent>
                                <DialogActions sx={calendarStyles}>
                                    <Button onClick={()=>setOpenCalendar(false)} variant="contained">Cancel</Button>
                                </DialogActions>
                            </Dialog>
                            </div>
                        </>
                    )}
                    <div className="col-12 mt-2">
                        <TextField
                            required
                            fullWidth
                            multiline
                            rows={3}
                            value={params?.note || ""}
                            onChange={(e) => handleChange("note", e.target.value)}
                            placeholder="Enter Lead Response"
                        />
                        <hr className="mt-1"/>
                    </div>

                    <div className="col-12 mt-2">
                        <div className="row">
                            <div className="col-5">
                                <input
                                    type={"number"}
                                    // id={id}
                                    className="form-control"
                                    style={{
                                        border: "1px solid #9747FF",
                                        height: "50px",
                                        borderRadius: 0,
                                    }}
                                    value={params?.leadScore || ""}
                                    // name={name}
                                    aria-describedby="NameHelp"
                                    placeholder={"Enter Lead Score"}
                                    onChange={(e) => handleChange("leadScore", e.target.value)}
                                />
                            </div>
                            <div className="col-4 ">
                                <CustomSelect
                                    value={params?.callStatus}
                                    onClick={(name, value) => {
                                        handleChange("callStatus", value);
                                    }}
                                    title="Call Status"
                                    options={options}
                                />
                            </div>
                            <div className="col-3 ">
                                {/*<Button fullWidth variant="contained" size="large"  onClick={() => {*/}
                                {/*    createNotesFollowUps();*/}
                                {/*}}>*/}
                                {/*    Save*/}
                                {/*</Button>*/}
                                <LoadingButton
                                    size="small"
                                    sx={{height: "100%", width: "100%"}}
                                    onClick={createNotesFollowUps}
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<Save/>}
                                    variant="contained"
                                >
                                    <span>Save</span>
                                </LoadingButton>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12 mt-4">
                {/* <FormControl sx={{width: '100%',mb:2, position: "sticky", top: "0"}} variant="outlined" */}
                <FormControl sx={{width: '100%',mb:2, top: "0"}} variant="outlined"
                             >
                    <FormHelperText id="outlined-global-note-helper-text" style={{color: "#888888"}}>Global Note: --
                        Click on Pencil
                        To make it Editable</FormHelperText>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        readOnly={!readOnly}
                        fullWidth
                        size="small"
                        value={params?.globalNote || ""}
                        onChange={(e) => handleChange("globalNote", e.target.value)}
                        aria-describedby="outlined-global-note-helper-text"
                        placeholder="Enter Global Note"
                        inputRef={inputRef}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    color="primary"
                                    aria-label="toggle password visibility"
                                    // onClick={()=>setReadOnly(!readOnly)}
                                    onMouseDown={(e) => e.preventDefault()}
                                    edge="end"
                                    onClick={() => {
                                        setReadOnly(!readOnly)
                                        setTimeout(() => {
                                            inputRef.current.focus();
                                        }, 100);
                                    }}
                                >
                                    {<Edit/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />

                </FormControl>
                <div className="previous-chats-container">

                    <div className="inner-container">
                        <div className="message-container">
                            <ChatBox messages={leadsAndFollowsUp}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-12 col-md-12 mt-4 previous-chats-container2">
                <Grid container spacing={2}>

                    <Grid item xs={6}>
                        <Typography variant="body2">WhatsApp Number:</Typography>
                        <Typography variant="caption"
                                    sx={{color: "#9747FF !important"}}>{lead?.mobile1IsWhatsapp ? lead?.mobile1 : lead?.mobile2IsWhatsapp ? lead?.mobile2 : "N/A"}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2">City:</Typography>
                        <Typography variant="caption">{lead?.city || "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">Email:</Typography>
                        <Typography variant="caption"
                                    sx={{color: "#9747FF !important"}}>{lead?.email || "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">DOB:</Typography>
                        <Typography variant="caption">{lead?.dob ? moment(lead?.dob).format("DD-MM-YYYY") : "N/A"}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2">Degree/Diploma:</Typography>
                        <Typography variant="caption">{lead?.degree?.name || "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">Board/University:</Typography>
                        <Typography variant="caption">{lead?.university?.name || "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">College:</Typography>
                        <Typography variant="caption">{lead?.college?.name || "N/A"}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2">Branch:</Typography>
                        <Typography variant="caption">{lead?.branch?.name || "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">Year of Passing:</Typography>
                        <Typography variant="caption">{lead?.yearOfPassing || "N/A"}</Typography>
                    </Grid>


                    <Grid item xs={6}>
                        <Typography variant="body2">Attended By:</Typography>
                        <Typography variant="caption">{lead?.AttendedBy?.name || "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">Created On:</Typography>
                        <Typography
                            variant="caption">{moment(lead?.createdAt).format("dddd, DD/MM/YYYY, LT") || "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">Lead Interests:</Typography>
                        <Typography
                            variant="caption">{lead?.interestedIn?.map(i => i?.course?.name && <Chip sx={{marginRight:"2px"}} label={i?.course?.name}/>)|| "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <LeadTagsModal open = {leadTag} onClose = {onClose} leadId = {lead?.id} assignLeadTags = {lead?.tags}/>
                        <Typography variant="body2">Lead Tags:<AddCircleOutline sx={{cursor:"pointer"}} onClick={()=>setleadTag(true)}/></Typography>
                        <Typography variant="caption">{lead?.tags?.map(i =>i?.tag?.name && <Chip sx={{marginRight:"2px"}}  label={i?.tag?.name}/>) || "N/A"}</Typography>
                    </Grid>
                </Grid>
            </div>   
            <div className="col-12  my-2">
                <div className="log-container">
                    <div className="mb-2">
                        <span className="me-2">Status Log</span>
                        {statusLog?.map((item) => {
                            return !!item?.status ? <>{`${item?.assignStatus?.name} >`}</> : "";
                        })}
                    </div>
                    <div>
                        <span className="me-2">Staff Log</span>
                        {statusLog?.map((item) => {
                            return !!item?.user?.name && <>{`${item?.user?.name} > `}</>;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

const calendarStyles = {
    backgroundColor: '#283046',
  };
/* eslint-disable */
import React, {useEffect, useState} from "react";
import {ActionButton} from "../components/buttons";
import {$crud} from "../services/CrudFactory";
import {usePagination} from "../hooks/usePagination";
import {useDispatch} from "react-redux";
import {useDataParams} from "../hooks/useDataParams";
import {columns} from "../helpers/tableData";
import DynamicTable from "../components/DynamicTable";
import {TransferLeadsModal, EmployeeDialog, AssignPermission, ConfirmationModal, ResetPasswordModal, OtpManagerModal, UserSettingModal, WhatsAppManagerModal} from "../dialogs";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";

export const Employee = () => {
    const dispatch = useDispatch();
    const {limit, setLimit, page, setPage, total, setTotal} = usePagination();
    const [employee, setEmployee] = useState(null);
    const [openEmployeeDialog, setOpenEmployeeDialog] = useState(false);
    const [openTransferModal, setOpenTransferModal] = useState(false);
    const [openPermissionDialog, setOpenPermissionDialog] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [otpManagerModal, setOtpManagerModal] = useState(false);
    const [whatsAppManagerModal, setWhatsAppManagerModal] = useState(false);
    const [userSettingModal, setUserSettingModal] = useState(false);
    // const [limitModal, setLimitModal] = useState(false);
    const {setFilter, filters} = useDataParams();
    const [employees, setEmployees] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentId, setCurrentId] = useState(0);
    const [permission, setPermission] = useState(null);
    const navigate = useNavigate();

    const retrieveUsers = async () => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                data: {users, total},
            } = await $crud.retrieve("web/users", {
                limit,
                page,
                ...filters,
            });
            setEmployees(users);
            setTotal(total);
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    };
    const statusChange = async (id) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {type, message} = await $crud.post(
                "update/web/user-status",
                {
                    id,
                    ...status
                }
            );
            retrieveUsers();
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
        setCurrentId(0);
    }

    const showContactStatus = async (id) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {type, message} = await $crud.post(
                "create/web/user/show-contact",
                {
                    id,
                    ...status
                }
            );
            retrieveUsers();
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
        setCurrentId(0);
    }

    const resetPassword = async (data) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {type, message} = await $crud.post(
                "update/web/reset-password",
                {
                    id: currentId,
                    ...data
                }
            );
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
            setShowResetPasswordModal(false);
        }
    }
    const otpApiKeyManager = async (data) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {type, message} = await $crud.post(
                "update/web/manage-otp-api-key",
                {
                    id: currentId,
                    ...data
                }
            );
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
            setOtpManagerModal(false);
        }
    }
    const whatsAppAccessKeyManager = async (data) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {type, message} = await $crud.post(
                "update/web/whatsApp-accessKey",
                {
                    id: currentId,
                    // accessKey:'94bb7e0f-5c5f-4db5-b44c-2bff3bf2dfe3@8534846301',
                    ...data
                }
            );
            dispatch({
                type: "SNACKBAR",
                snackBar: {type, message},
            });
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
            setWhatsAppManagerModal(false);
        }
    }
    const logoutFromAllDevices = async () => {
        const {message} = await $crud.post("create/web/logout", {userId: currentId});
        if (message) {
            setShowConfirmationModal(false);
        }
    }


    const menuOptions = [
        {
            title: "Edit",
            onClick: () => {
                setOpenEmployeeDialog(true);
            }
        },
        {
            title: "Assign Permission",
            onClick: () => {
                setOpenPermissionDialog(true);
            }
        },
        {
            title: "Reset Password",
            onClick: (value) => {
                setShowResetPasswordModal(true); 
                setCurrentId(value.id);
            }
        },
        {
            title: "OTP Manager",
            onClick: (value) => {
                setOtpManagerModal(true);
                setCurrentId(value.id);
            }
        },
        {
            title: "WhatsApp Manager",
            onClick: (value) => {
                setWhatsAppManagerModal(true);
                setCurrentId(value.id);
            }
        },
        {
            title: "User Setting",
            onClick: (value) => {
                setUserSettingModal(true);
                setCurrentId(value.id);
            },
            // submenu: [
            //     {
            //         title: "Show Contact",
            //         onClick: (value) => {
            //             setShowContactModal(true);
            //             setCurrentId(value.id);
            //         }
            //     },
            //     {
            //         title: "Follow Up Limits",
            //         onClick: (value) => {
            //             setLimitModal(true);
            //             setCurrentId(value.id);
            //         }
            //     }
            // ]
        },
        // {
        //     title: "Follow Up Limits",
        //     onClick: (value) => {
        //         setLimitModal(true);
        //         setCurrentId(value.id);
        //     }
        // },
        {
            title: "Logout From All Devices",
            onClick: (value) => {
                setShowConfirmationModal(true);
                setCurrentId(value.id);
            }
        }
    ]


    useEffect(() => {
        retrieveUsers();
    }, [limit, page, filters]);

    // console.log(employees, "---------employees");

    return (
        <>
            <div>
                <div className="row w-100 g-2">
                    <div className="mb-3 col-6 d-flex">
                        <ActionButton
                            onClick={() => {
                                setEmployee(null);
                                setOpenEmployeeDialog(true);
                            }}
                            buttonName="Add Staff Member"
                        />
                        <ActionButton
                            onClick={() => setOpenTransferModal(true)}
                            buttonName="Transfer Leads"
                        />
                    </div>

                    <div className="mb-3 col-3">
                        <div className="input-group">
                            <select
                                onChange={(e) => setFilter("isActive", e.target.value)}
                                className="form-select"
                                style={{
                                    backgroundColor: "#283046",
                                    border: "1px solid #5c3fdd",
                                }}
                                aria-label="Default select example"
                            >
                                <option value="any" selected>
                                    Status
                                </option>
                                <option value="1" selected>
                                    Active
                                </option>
                                <option value="0" selected>
                                    InActive
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-3 col-3">
                        <input
                            onChange={(e) => setFilter("search", e.target.value)}
                            className="form-control InputBox"
                            type="search"
                            placeholder="Search"
                            aria-label="Search" 
                        />
                    </div>
                </div>
            </div>

            <DynamicTable
                rows={employees}
                columns={columns?.user}
                style={{maxHeight: "calc(100vh - 100px)"}}
                total={total}
                anchorEl={anchorEl}
                onRowClick={() => {
                }}
                onStatusChange={(id) => {
                    statusChange(id)
                }}
                onShowContactStatus = {(id) => {
                    showContactStatus(id)
                }} 
                onEdit={(value) => {
                    setOpenEmployeeDialog(true)

                }}
                onPermissionEdit={() => {
                    setOpenPermissionDialog(true);
                }}
                onDelete={(id) => {
                    setShowConfirmationModal(true);
                    setCurrentId(id);
                }}
                onClose={() => {
                    setAnchorEl(null);
                    setEmployee(null);
                    setPermission(null);

                }}
                onOpen={(event, value) => {
                    setAnchorEl(event.currentTarget);
                    setEmployee(value);
                    setPermission(value);
                }}
                menuOptions={menuOptions}
                paginationProps={{
                    limit: limit,
                    page: page,
                    onChangeLimit: (limit) => setLimit(limit),
                    onChangePage: (page) => setPage(page),
                }}
            />
            <EmployeeDialog
                employee={employee}
                onSave={retrieveUsers}
                onClose={() => setOpenEmployeeDialog(false)}
                open={openEmployeeDialog}
            />
            <AssignPermission
                onSave={retrieveUsers}
                onClose={() => setOpenPermissionDialog(null)}
                employee={permission}
                open={openPermissionDialog}
            />
            <ConfirmationModal
                open={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                content={<Typography variant="body1" component="span">Press "Yes" To Log This User Out of every
                    Device</Typography>}
                onOk={logoutFromAllDevices}
            />
            <TransferLeadsModal
                open={openTransferModal}
                onClose={() => setOpenTransferModal(false)}
            />
            <ResetPasswordModal
                open={showResetPasswordModal}
                onClose={() => setShowResetPasswordModal(false)}
                onSubmit={(data) => resetPassword(data)}
            />
            <OtpManagerModal
                open={otpManagerModal}
                onClose={() => setOtpManagerModal(false)}
                onSubmit={(data) => otpApiKeyManager(data)}
            />
            <WhatsAppManagerModal
                open={whatsAppManagerModal}
                onClose={() => setWhatsAppManagerModal(false)}
                onSubmit={(data) => whatsAppAccessKeyManager(data)}
            />
            <UserSettingModal
                open={userSettingModal}
                onClose={() => setUserSettingModal(false)}
                // onSubmit={(data) => otpApiKeyManager(data)}
            />

            {/* <FollowUpLimitModal 
                open = {limitModal}
                onClose = {()=>setLimitModal(false)}
            /> */}
            
        </>
    );
};

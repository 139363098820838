/* eslint-disable */
import React, {useState} from "react";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {maskMobile, maskEmail} from "../../../features/Features";
import IconButton from "@mui/material/IconButton";
import {Edit} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LeadForm from "../../LeadForm";
import {useDataParams} from "../../../hooks/useDataParams";
import Chip from "@mui/material/Chip";

export function LeadDetails(props) {
    const {setViewLead  , lead: leads,formProps} = props;
    const userPermissions = useSelector((state) => state.userReducer).userPermissions?.map(p => p.permissionId);
    const [showLead, setShowLead] = useState(false)
    const {setParams} = useDataParams();

    return (
        <div>
            <div className="d-flex justify-content-between mb-2">
                <div className="heading">
                    Lead Details
                    {userPermissions?.length && userPermissions.includes(14)
                    && (
                        <IconButton size="small" sx={{marginLeft: "10px"}} >
                            <Edit/>
                        </IconButton>
                    )}
                </div>
                <div className="lead-count">
                    No. Of Time Created Lead : {leads?.leadCount}
                </div>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography variant="body2">Name:</Typography>
                    <Typography variant="caption">{leads?.name || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Father's Name:</Typography>
                    <Typography variant="caption">{leads?.fatherName || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">WhatsApp Number:</Typography>
                    <Typography variant="caption"
                                sx={{color: "#9747FF !important"}}>{leads?.mobile1IsWhatsapp ? leads?.mobile1 : leads?.mobile2IsWhatsapp ? leads?.mobile2 : "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Mobile Number:</Typography>
                    <Typography variant="caption"
                                sx={{color: "#9747FF !important"}}>{maskMobile(leads?.mobile1) || leads?.mobile2 || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">City:</Typography>
                    <Typography variant="caption">{leads?.city || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Email:</Typography>
                    <Typography variant="caption"
                                sx={{color: "#9747FF !important"}}>{leads?.email || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">DOB:</Typography>
                    <Typography variant="caption">{leads?.dob ? moment(leads?.dob).format("DD-MM-YYYY") : "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Complete Address:</Typography>
                    <Typography variant="caption">{leads?.address || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">State:</Typography>
                    <Typography variant="caption">{leads?.state || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">PinCode:</Typography>
                    <Typography variant="caption">{leads?.pincode || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Degree/Diploma:</Typography>
                    <Typography variant="caption">{leads?.degree?.name || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Board/University:</Typography>
                    <Typography variant="caption">{leads?.university?.name || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">College:</Typography>
                    <Typography variant="caption">{leads?.college?.name || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Lead Category:</Typography>
                    <Typography variant="caption">{leads?.leadType?.name || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Branch:</Typography>
                    <Typography variant="caption">{leads?.branch?.name || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Year of Passing:</Typography>
                    <Typography variant="caption">{leads?.yearOfPassing || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Known About Technosters Through/ Lead Source:</Typography>
                    <Typography variant="caption">{leads?.source?.name || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Web To Lead Id:</Typography>
                    <Typography variant="caption">{"N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Attended By:</Typography>
                    <Typography variant="caption">{leads?.AttendedBy?.name || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Created On:</Typography>
                    <Typography
                        variant="caption">{moment(leads?.createdAt).format("dddd, DD/MM/YYYY, LT") || "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Lead Interests:</Typography>
                    <Typography
                        variant="caption">{leads?.interestedIn?.map(i => i?.course?.name && <Chip sx={{marginRight:"2px"}} label={i?.course?.name}/>)|| "N/A"}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">Lead Tags:</Typography>
                    <Typography variant="caption">{leads?.tags?.map(i =>i?.tag?.name && <Chip sx={{marginRight:"2px"}}  label={i?.tag?.name}/>) || "N/A"}</Typography>
                </Grid>
            </Grid>
            <LeadForm
                leadId={leads?.id}
                anchor="right"
                // open={showLead}
                // onClose={()=>{
                //     formProps.onChange();
                //     setShowLead(false);
                // }}
                // onNew={()=>formProps?.onNew()}
                // onOpen={()=>formProps?.onOpen()}
            />
        </div>
    );
}

import React from "react";
import {Box, Stack} from "@mui/material";
import WhatsappChatBubble from "./WhatsappChatBubble";

export const WhatsappChatContainer = (props) =>{
    const {messages} = props;
    return (
        <Box
            sx={{
                display: "flex",
                flex: 1,
                minHeight: 0,
                px: 2,
                py: 3,
                overflowY: "scroll",
                flexDirection: "column-reverse",
            }}
        >
            <Stack spacing={2} justifyContent="flex-end">
                {messages.map((message, index) => {
                    const isYou = message.sender === "You";
                    return (
                        <Stack
                            key={index}
                            direction="row"
                            spacing={2}
                            flexDirection={isYou ? "row-reverse" : "row"}
                        >
                            <WhatsappChatBubble variant={isYou ? "sent" : "received"} {...message} />
                        </Stack>
                    );
                })}
            </Stack>
        </Box>
    );
};
/* eslint-disable */
import {io} from "socket.io-client";
import {IMAGE_BASE_URL} from "./constants";
import {store} from "./redux/store";
import {
    changeCallHoldStatus,
    changeCallMuteStatus,
    changeCallSpeakerStatus,
    setCallData,
    setCallLog,
    setFollowUpLead,
    setFollowUpLeadId,
    setFollowUpModalOpen,
    setSystemSettings,
    startCallTimer,
    stopCallTimer,
} from "./redux/actions";

const socket = io(IMAGE_BASE_URL, {
    autoConnect: false,
    auth: {
        userId: "",
        mode: "web",
    },
});

socket.on("connection", (data) => {
});

// socket.off().on("users", (data) => {
//     console.log({data});
//     data.length && localStorage.setItem("users", JSON.stringify(data[0]));
// });

socket.onAny((event, ...args) => {
    console.log(event, args);
    if (event === "users") {
        args.length &&
        args[0].length &&
        localStorage.setItem("users", JSON.stringify(args[0][0]));
    }
    if (event === "openLead") {
        // store.dispatch(setFollowUpModalOpen(true))
        store.dispatch(
            setFollowUpLeadId({
                id: args[0]?.leadId || null,
                mobile1: args[0]?.mobile1 || null,
                openLeadModal: true,
            })
        );
    }
    if (event === "userCallLog") {
        store.dispatch(setCallLog({...args[0]}));
    }
    if (event === "callstatus") {
        if (args[0]?.status === "Disconnected") {
            // console.log({data}, "disconnected");

            store.dispatch(setCallData({name: null, callStatus: args[0]?.status}));
            // store.dispatch(setFollowUpLeadId({}))
            store.dispatch(stopCallTimer());
        } else if (args[0]?.status === "Connected") {
            // console.log({data}, "connected");
            store.dispatch(
                setCallData({
                    name: args[0]?.name ?? "UnSaved",
                    callStatus: args[0]?.status,
                    mobile: args[0]?.mobile,
                })
            );
            store.dispatch(startCallTimer(true));
        } else if (args[0]?.status === "Missed") {
            // console.log({data}, "Missed");
            store.dispatch(
                setCallData({name: args[0]?.name, callStatus: "Missed"})
            );
        } else if (args[0]?.status === "incomingConnected") {
            // console.log({data}, "incoming Connected");
            store.dispatch(startCallTimer(true));
            store.dispatch(
                setCallData({
                    name: args[0]?.name ?? "UnSaved",
                    callStatus: "Call Connected",
                    fromPhone: args[0]?.fromPhone,
                    mobile: args[0]?.mobile,
                })
            );
        } else if (args[0]?.status === "incomingArrived") {
            // console.log({data}, "incoming Arrived");
            // console.log(data?.name,"name")
            store.dispatch(
                setCallData({
                    name: args[0]?.name ?? "UnSaved",
                    callStatus: "Incoming Call",
                    incoming: true,
                    mobile: args[0]?.mobile,
                })
            );
        } else if (args[0]?.status === "outgoingInit") {
            // console.log({data}, "outgoingInit");
            store.dispatch(
                setCallData({
                    name: args[0]?.name ?? "UnSaved",
                    callStatus: "Ringing...",
                    mobile: args[0]?.mobile,
                })
            );
        }
    }
    if (event === "ringing..") {
        store.dispatch(
            setCallData({name: args[0]?.name, callStatus: args[0]?.status})
        );
    }
    if (event === "systemSetting") {
        store.dispatch(setSystemSettings(args[0]?.systemSettings));
    }
    if (event === "hold"){
        store.dispatch(changeCallHoldStatus())
    }
    if (event === "mute"){
        console.log("heoollll")
        store.dispatch(changeCallMuteStatus())
    }
    if (event === "speaker"){
        store.dispatch(changeCallSpeakerStatus())
    }
});
// socket.off().on("ringing..", (data) => {
//     dispatch(setCallData({name: data?.name, callStatus: data?.status}))
// })
//
// socket.off().on("systemSetting", (data) => {
//     dispatch(setSystemSettings(data?.systemSettings))
// })
// socket.off().on("userCallLog", (data) => {
//         console.log()
//     // dispatch(setCallLog({...data}))
// })

export default socket;

/* eslint-disable */
import {
  Avatar,
  Button,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
  Fab,
  Link,
  Typography,
  Rating,
  Checkbox,
} from "@mui/material";
import React from "react";
import { formatTime, formateDateAndTime, stringAvatar } from "./functions";
import {
  ADMIN_ROLE,
  BANK_LOGO_BASE_URL,
  EMPLOYEE_IMAGE_BASE_URL,
  SUPER_ADMIN_ROLE,
} from "../constants";
import moment from "moment";
import {
  AttachFile,
  Edit,
  Subject,
  DeleteForever,
  StarBorder,
  Star,
  LocalFireDepartment,
  Call,
  Delete,
  MoreHoriz,
} from "@mui/icons-material";
import socket from "../socket";
import { useDispatch } from "react-redux";
import { setCallData } from "../redux/actions";
import Box from "@mui/material/Box";
import dayjs from "dayjs";

export function LocalFireDepartmentGradient(props) {
  const { sx } = props;
  return (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="gold" />
          <stop offset={1} stopColor="red" />
        </linearGradient>
      </svg>
      <LocalFireDepartment sx={{ ...sx, fill: "url(#linearColors)" }} />
    </>
  );
}

function makeCall(callingName, mobile) {
  // const dispatch = useDispatch();
  let name = callingName || "UnSaved";
  localStorage.getItem("users") &&
    socket.emit("makeCall", {
      name,
      mobile,
      socketId: JSON.parse(localStorage.getItem("users"))?.socketId,
    });
  // dispatch(setCallData({mobile, name,  socketId: JSON.parse(localStorage.getItem("users"))?.socketId}))
  // callProgressModal({open:true, data:{name, mobile, socketId: JSON.parse(localStorage.getItem("users"))?.socketId}})
}

const SSLS = localStorage.getItem("systemSettings");
const contactEnable = localStorage.getItem("showContact");
// console.log(contactEnable, "---------contactEnable");
const systemSettings = SSLS && JSON.parse(SSLS);

export const columns = {
  studentLead: [
    {
      id: "checkBox",
      title: "selectAllRows",
      renderCell: ({ id, handleSelectRow, isChecked, userRole, routeName }) =>
        (userRole === ADMIN_ROLE || userRole === SUPER_ADMIN_ROLE) && (
          // ["unsaved", "unAssigned"].includes(routeName) && (
          <Checkbox
            checked={isChecked}
            sx={{ p: 0 }}
            onChange={(e) => {
              handleSelectRow(id);
            }}
            onClick={(e) => e.stopPropagation()}
          />
        ),
      // ),
      width: 20,
    },

    {
      id: "id",
      title: "Id",
      renderCell: ({ value }) => {
        return `#${value}`;
      },
    },
    {
      id: "name",
      title: "Name",
      renderCell: ({ value }) => {
        return (
          <Grid container justifyContent="space-between">
            <Typography variant="caption" component="span">
              {value}
            </Typography>
          </Grid>
        );
      },
    },
    {
      id: "mobile",
      title: "Contact No.",
      renderCell: ({ row, value, isMobileHide }) => {
        return (
          <Grid
            container
            flexDirection={isMobileHide ? "row" : "column"}
            spacing={2}
          >
            <Grid item>
              {contactEnable === "false" ? (
                <Fab
                  color="primary"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    makeCall(row?.name, value?.first);
                  }}
                  sx={{ zIndex: "inherit" }}
                >
                  <Call
                    style={{ fill: "white", fontSize: "20px" }}
                    className="animate__animated animate__tada animate__infinite infinite"
                  />
                </Fab>
              ) : (
                <a
                  style={{
                    background: "#9747FF",
                    padding: "6px 10px",
                    textDecoration: "none",
                    color: "#fff",
                    borderRadius: "5px",
                    zIndex: "inherit",
                  }}
                  href={`tel:${value?.first}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    makeCall(row?.name, value?.first);
                  }}
                >
                  {value?.first}
                </a>
              )}
              {/* {isMobileHide ? (
                <Fab
                  color="primary"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    makeCall(row?.name, value?.first);
                  }}
                  sx={{ zIndex: "inherit" }}
                >
                  <Call
                    style={{ fill: "white", fontSize: "20px" }}
                    className="animate__animated animate__tada animate__infinite infinite"
                  />
                </Fab>
              ) : (
                <a
                  style={{
                    background: "#9747FF",
                    padding: "6px 10px",
                    textDecoration: "none",
                    color: "#fff",
                    borderRadius: "5px",
                    zIndex: "inherit",
                  }}
                  href={`tel:${value?.first}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    makeCall(row?.name, value?.first);
                  }}
                >
                  {value?.first}
                </a>
              )} */}
            </Grid>
            {value?.second && (
              <Grid item>
                {contactEnable === "false" ? (
                  <Fab
                    color="secondary"
                    onClick={(e) => {
                      e.stopPropagation();
                      makeCall(row?.name, value?.second);
                    }}
                    size="small"
                    sx={{ zIndex: "inherit" }}
                  >
                    <Call
                      style={{ fill: "white", fontSize: "20px" }}
                      className="animate__animated animate__tada animate__infinite infinite"
                    />
                  </Fab>
                ) : (
                  <a
                    style={{
                      background: "#9EA2FF",
                      padding: "6px 10px",
                      textDecoration: "none",
                      color: "#fff",
                      borderRadius: "5px",
                      zIndex: "inherit",
                    }}
                    href={`tel:${value?.second}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      makeCall(row?.name, value?.second);
                    }}
                  >
                    {value?.second}
                  </a>
                )}
                {/* {isMobileHide ? (
                  <Fab
                    color="secondary"
                    onClick={(e) => {
                      e.stopPropagation();
                      makeCall(row?.name, value?.second);
                    }}
                    size="small"
                    sx={{ zIndex: "inherit" }}
                  >
                    <Call
                      style={{ fill: "white", fontSize: "20px" }}
                      className="animate__animated animate__tada animate__infinite infinite"
                    />
                  </Fab>
                ) : (
                  <a
                    style={{
                      background: "#9EA2FF",
                      padding: "6px 10px",
                      textDecoration: "none",
                      color: "#fff",
                      borderRadius: "5px",
                      zIndex: "inherit",
                    }}
                    href={`tel:${value?.second}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      makeCall(row?.name, value?.second);
                    }}
                  >
                    {value?.second}
                  </a>
                )} */}
              </Grid>
            )}
          </Grid>
        );
      },
    },
    // {
    //   id: "followUp",
    //   title: "Lead Score",
    //   renderCell: ({ value }) => {
    //     return (
    //       (!!value?.length &&
    //         value?.filter((f) => !!f.leadScore).length &&
    //         Number(
    //           value
    //             ?.filter((f) => !!f.leadScore)
    //             ?.reduce((acc, cur) => {
    //               if (cur?.leadScore) {
    //                 acc += Number(cur?.leadScore);
    //               }
    //               return acc;
    //             }, 0) / Number(value?.filter((f) => !!f.leadScore)?.length)
    //         ).toFixed(2)) ||
    //       "N/A"
    //     );
    //   },
    // },
    {
      id: "leadConvertType",
      title: "Lead Type",
      renderCell: ({ value }) => {
        return (
          <Button
            style={{
              borderColor: value?.color,
              color: value?.color,
              padding: "2px 10px",
            }}
            variant="outlined"
            size="small"
            onClick={(e) => e.stopPropagation()}
          >
            {value ? value?.name : "N/A"}
          </Button>
        );
      },
    },
    {
      id: "user",
      title: "Assign To",
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value?.name} placement="top-end">
            {value?.name ? (
              <Avatar
                {...stringAvatar(value?.name)}
                src={EMPLOYEE_IMAGE_BASE_URL + `${value?.photo}`}
                imgProps={{
                  lazy:true
                }}
                alt={value?.name}
              />
            ) : (
              <Avatar />
            )}
          </Tooltip>
        );
      },
    },
    {
      id: "followUp",
      title: "Last Response",
      renderCell: ({ value }) => {
        return (
          !!value?.length &&
          value
            ?.reduce((prev, next) =>
              prev?.createdAt > next?.createdAt ? prev : next
            )
            ?.note?.split(" ")
            .slice(0, 40)
            .join(" ")
        );
      },
    },
    {
      id: "followUp",
      title: "Call Status",
      renderCell: ({ value }) => {
        return (
          <Button
            color="secondary"
            size="small"
            variant="outlined"
            sx={{
              padding: "2px 10px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {(!!value?.length &&
              value?.reduce((prev, next) =>
                prev?.createdAt > next?.createdAt ? prev : next
              )?.callStatus) ||
              "N/A"}
          </Button>
        );
      },
    },
    {
      id: "status",
      title: "Lead Status",
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value?.description || "N/A"} placement="top-end">
            <Button
              size="small"
              variant="outlined"
              style={{
                borderColor: value?.color,
                color: value?.color,
                padding: "2px 10px",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {value?.name || "N/A"}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      id: "schedule",
      title: "Next Follow Up Date",
      renderCell: ({ value }) => {
        return (
          <Chip
            label={
              // value ? moment(`${value}`).format("DD-MM-YYYY | LT") : "No Followups Scheduled"
              value ?? "No Followups Scheduled"
            }
          />
        );
      },
    },
    // {
    //   id: "createdAt",
    //   title: "Created At",
    //   renderCell: ({ value }) => {
    //     return (
    //       <Chip
    //         label={
    //           value
    //             ? moment(`${value}`).format("DD-MMM-YYYY | LT")
    //             : "No Followups Scheduled"
    //           // value ?? "No Followups Scheduled"
    //         }
    //       />
    //     );
    //   },
    // },
    {
      id: "action",
      title: "Action",
      renderCell: ({
        row,
        selectedButton,
        i,
        userPermissions,
        onEdit,
        id,
        userRole,
        onDelete,
        routeName,
        setIsHot,
        setIsFavorite,
      }) => {
        return (
          <Grid container>
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              {/*<Box>*/}
              <Tooltip title="Favorite">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsFavorite(!Boolean(row?.isFavorite));
                  }}
                >
                  {row?.isFavorite ? <Star sx={{ fill: "gold" }} /> : <Star />}
                </IconButton>
              </Tooltip>
              <Tooltip title="Hot">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsHot(!row?.isHot);
                  }}
                >
                  {row?.isHot ? (
                    <LocalFireDepartmentGradient />
                  ) : (
                    <LocalFireDepartment />
                  )}
                </IconButton>
              </Tooltip>
              {/*</Box>*/}
              {userPermissions?.length && userPermissions.includes(14) && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(id);
                  }}
                  style={{
                    color: selectedButton === i ? "black" : "white",
                  }}
                >
                  <Edit />
                </IconButton>
              )}
            </Grid>
            <Grid item>
              {userRole === "admin" && routeName === "unsaved" && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(id);
                  }}
                  style={{
                    color: selectedButton === i ? "black" : "white",
                  }}
                >
                  <Delete />
                </IconButton>
              )}
            </Grid>
          </Grid>
        );
      },
    },
  ],
  courseTemplate: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "course",
      title: "Course Name",
      renderCell: ({ value }) => {
        return value?.name;
      },
    },
    {
      id: "templateType",
      title: "Template Type",
    },
    {
      id: "message",
      title: "Message",
    },
    {
      id: "status",
      title: "Status",
      renderCell: ({ value }) => {
        return value ? "Active" : "InActive";
      },
    },
    {
      id: "action",
      title: "Action",
      renderCell: ({ value, onEdit, onStatusChange, id }) => {
        return (
          <React.Fragment>
            <Tooltip style={{ cursor: "pointer" }} title="Status">
              <Switch
                checked={value?.status}
                onClick={(e) => e.stopPropagation()}
                onChange={() => {
                  onStatusChange(id);
                }}
                size="small"
                color="primary"
              />
            </Tooltip>
            <Tooltip style={{ cursor: "pointer" }} title="Edit">
              <Edit
                size="small"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(value);
                }}
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ],
  course: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "name",
      title: "Course Name",
    },
    {
      id: "duration",
      title: "Duration",
    },
    {
      id: "fee",
      title: "Course Fees",
    },
    {
      id: "fee_chart",
      title: "Select Emi Month",
      renderCell: ({ value, id, onFeeEmiChange }) => {
        return (
          <select
            onChange={(e) => {
              e.stopPropagation();
              onFeeEmiChange(id, e.target.value);
            }}
            className="form-select"
            style={{
              background: "#343d55",
              color: "#e4e4e4",
              border: "1px solid green",
            }}
            aria-label="Default select example"
          >
            <option value={null}>Select Emi</option>
            {value?.map((val, index) => {
              return (
                <option key={index} value={val && JSON.stringify(val)}>
                  {val?.totalMonthEMI}
                </option>
              );
            })}
          </select>
        );
      },
    },
    {
      id: "emiDownPayment",
      title: "Down Payment",
    },
    {
      id: "action",
      title: "Monthly Emi",
      renderCell: ({ value }) => {
        return value["selectedEmi"] ? value?.selectedEmi : "NA";
      },
    },
    {
      id: "action",
      title: "Course Module",
      renderCell: ({ value, onStatusChange }) => {
        return (
          <React.Fragment>
            <Fab
              size="small"
              sx={{ borderRadius: "5px", marginRight: "10px", zIndex: "1" }}
              color="primary"
              disabled={!value?.courseModule ? true : false}
              onClick={(e) => {
                e.stopPropagation();
                onStatusChange(value?.courseModule, false);
              }}
            >
              <Tooltip title="Preview Text">
                <Subject />
              </Tooltip>
            </Fab>
            <Fab
              size="small"
              sx={{ borderRadius: "5px", marginRight: "10px", zIndex: "1" }}
              color="primary"
              disabled={!value?.pdf ? true : false}
              onClick={(e) => {
                e.stopPropagation();
                onStatusChange(value?.pdf, true);
              }}
            >
              <Tooltip title="Preview PDF">
                <AttachFile />
              </Tooltip>
            </Fab>
          </React.Fragment>
        );
      },
    },
    {
      id: "action",
      title: "Action",
      renderCell: ({ value, menuProps: { open, onClick } }) => {
        return (
          <React.Fragment>
            <Fab
              onClick={(e) => {
                e.stopPropagation();
                onClick(e, value);
              }}
              size="small"
              sx={{ borderRadius: "5px", marginRight: "10px", zIndex: "1" }}
              color="primary"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <MoreHoriz />
            </Fab>
          </React.Fragment>
        );
      },
    },
  ],
  source: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "name",
      title: "Source Name",
    },
    {
      id: "description",
      title: "Description",
    },
    {
      id: "noOfLeads",
      title: "Total Leads",
      renderCell: ({ value }) => (
        <Button variant="contained" color={"secondary"}>
          {value || "0"}
        </Button>
      ),
    },
    {
      id: "noOfConvertedLeads",
      title: "Converted Leads",
      renderCell: ({ value }) => (
        <Button variant="contained" color={"secondary"}>
          {value || "0"}
        </Button>
      ),
    },
    {
      id: "totalSpend",
      title: "Total Spend",
      renderCell: ({ value }) => (
        <Button variant="contained" color={"primary"}>
          {value || "0"}
        </Button>
      ),
    },
    {
      id: "expenses",
      title: "Total Earn",
      renderCell: ({ value }) => (
        <Button variant="contained" color={"primary"}>
          {value?.expenditure || "0"}
        </Button>
      ),
    },
    {
      id: "action",
      title: "Action",
      renderCell: ({ value, onStatusChange, id, onEdit }) => {
        return (
          <React.Fragment>
            <Tooltip style={{ cursor: "pointer" }} title="Status">
              <Switch
                checked={value?.isActive}
                onChange={() => onStatusChange(id)}
                size="small"
                color="primary"
              />
            </Tooltip>
            <Tooltip
              onClick={() => onEdit(value)}
              style={{ cursor: "pointer" }}
              title="Edit"
            >
              <Edit size="small" color="primary" />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ],
  announcements: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "topic",
      title: "Topic Name",
    },
    {
      id: "topicDescription",
      title: "Description",
    },
    {
      id: "startDate",
      title: "StartDate",
      renderCell: (value) => formateDateAndTime(value?.row?.startDate),
    },
    {
      id: "endDate",
      title: "EndDate",
      renderCell: (value) => formateDateAndTime(value?.row?.endDate),
    },
    {
      id: "createdByName",
      title: "CreatedByName",
    },
    {
      id: "action",
      title: "Action",
      renderCell: ({ value, onStatusChange, id, onEdit }) => {
        return (
          <React.Fragment>
            <Tooltip style={{ cursor: "pointer" }} title="Status">
              <Switch
                checked={value?.status}
                onChange={() => onStatusChange(id)}
                size="small"
                color="primary"
              />
            </Tooltip>
            <Tooltip
              onClick={() => onEdit(value)}
              style={{ cursor: "pointer" }}
              title="Edit"
            >
              <Edit size="small" color="primary" />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ],
  branch: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "branchCode",
      title: "Branch Code",
    },
    {
      id: "name",
      title: "Branch Name",
    },
    {
      id: "description",
      title: "Description",
    },
    {
      id: "address",
      title: "Address",
    },
    {
      id: "mobileNo1",
      title: "Contact Phone",
    },
    {
      id: "email",
      title: "Contact Email",
    },
    {
      id: "action",
      title: "Action",
      renderCell: ({ value, onStatusChange, onEdit, id }) => {
        return (
          <React.Fragment>
            <Tooltip style={{ cursor: "pointer" }} title="Status">
              <Switch
                checked={value?.isActive}
                onChange={async () => onStatusChange(id)}
                size="small"
                color="primary"
              />
            </Tooltip>
            <Tooltip
              onClick={() => onEdit(value)}
              style={{ cursor: "pointer" }}
              title="Edit"
            >
              <Edit size="small" color="primary" />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ],
  status: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "name",
      title: "Status Name",
    },
    {
      id: "noOfLeads",
      title: "No. Of Leads",
      renderCell: ({ value }) => (
        <Button variant="contained" color={"secondary"}>
          {value || "0"}
        </Button>
      ),
    },
    {
      id: "color",
      title: "Status Color",
    },
    {
      id: "description",
      title: "Reason",
    },
    {
      id: "defaultStatus",
      title: "Default",
      renderCell: ({ value, onDefaultStatusChange, id }) => {
        return (
          <Tooltip
            style={{ cursor: "pointer" }}
            title="Make Default"
            placement="right"
          >
            <Switch
              checked={value}
              onChange={() => onDefaultStatusChange(id)}
              size="small"
              color="primary"
            />
          </Tooltip>
        );
      },
    },
    {
      id: "action",
      title: "Action",
      renderCell: ({ value, onStatusChange, onEdit, id }) => {
        return (
          <React.Fragment>
            <Tooltip style={{ cursor: "pointer" }} title="Status">
              <Switch
                checked={value?.isActive}
                onChange={async () => onStatusChange(id)}
                size="small"
                color="primary"
              />
            </Tooltip>
            <Tooltip
              onClick={() => onEdit(value)}
              style={{ cursor: "pointer" }}
              title="Edit"
            >
              <Edit size="small" color="primary" />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ],
  colleges: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "name",
      title: "College Name",
    },
    {
      id: "noOfLeads",
      title: "No. Of Leads",
      renderCell: ({ value }) => (
        <Button variant="contained" color={"secondary"}>
          {value || "0"}
        </Button>
      ),
    },
    {
      id: "action",
      title: "Created By",
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value?.user?.name} placement="top-end">
            {value?.user?.name ? (
              <Avatar
                {...stringAvatar(value?.user?.name)}
                src={EMPLOYEE_IMAGE_BASE_URL + `${value?.user?.photo}`}
                alt={value?.user?.name}
              />
            ) : (
              <Avatar />
            )}
          </Tooltip>
        );
      },
    },

    {
      id: "action",
      title: "Action",
      renderCell: ({ value, onEdit, onDelete, id }) => {
        return (
          <React.Fragment>
            <Tooltip
              onClick={() => onEdit(value)}
              style={{ cursor: "pointer" }}
              title="Edit"
            >
              <Edit size="small" color="primary" />
            </Tooltip>
            <Tooltip
              onClick={() => onDelete(id)}
              style={{ cursor: "pointer" }}
              title="Delete"
            >
              <DeleteForever size="small" color="primary" />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ],
  degrees: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "name",
      title: "Degree Name",
    },
    {
      id: "noOfLeads",
      title: "No. Of Leads",
      renderCell: ({ value }) => (
        <Button variant="contained" color={"secondary"}>
          {value || "0"}
        </Button>
      ),
    },
    {
      id: "action",
      title: "Created By",
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value?.user?.name} placement="top-end">
            {value?.user?.name ? (
              <Avatar
                {...stringAvatar(value?.user?.name)}
                src={EMPLOYEE_IMAGE_BASE_URL + `${value?.user?.photo}`}
                alt={value?.user?.name}
              />
            ) : (
              <Avatar />
            )}
          </Tooltip>
        );
      },
    },

    {
      id: "action",
      title: "Action",
      renderCell: ({ value, onEdit, onDelete, id }) => {
        return (
          <React.Fragment>
            <Tooltip
              onClick={() => onEdit(value)}
              style={{ cursor: "pointer" }}
              title="Edit"
            >
              <Edit size="small" color="primary" />
            </Tooltip>
            <Tooltip
              onClick={() => onDelete(id)}
              style={{ cursor: "pointer" }}
              title="Delete"
            >
              <DeleteForever size="small" color="primary" />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ],
  universities: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "name",
      title: "University Name",
    },
    {
      id: "noOfLeads",
      title: "No. Of Leads",
      renderCell: ({ value }) => (
        <Button variant="contained" color={"secondary"}>
          {value || "0"}
        </Button>
      ),
    },
    {
      id: "action",
      title: "Created By",
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value?.user?.name} placement="top-end">
            {value?.user?.name ? (
              <Avatar
                {...stringAvatar(value?.user?.name)}
                src={EMPLOYEE_IMAGE_BASE_URL + `${value?.user?.photo}`}
                alt={value?.user?.name}
              />
            ) : (
              <Avatar />
            )}
          </Tooltip>
        );
      },
    },

    {
      id: "action",
      title: "Action",
      renderCell: ({ value, onEdit, onDelete, id }) => {
        return (
          <React.Fragment>
            <Tooltip
              onClick={() => onEdit(value)}
              style={{ cursor: "pointer" }}
              title="Edit"
            >
              <Edit size="small" color="primary" />
            </Tooltip>
            <Tooltip
              onClick={() => onDelete(id)}
              style={{ cursor: "pointer" }}
              title="Delete"
            >
              <DeleteForever size="small" color="primary" />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ],
  leadConvertTypes: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "name",
      title: "Convert Type Name",
    },
    {
      id: "noOfLeads",
      title: "No. Of Leads",
      renderCell: ({ value }) => (
        <Button variant="contained" color={"secondary"}>
          {value || "0"}
        </Button>
      ),
    },

    {
      id: "action",
      title: "Action",
      renderCell: ({ value, onEdit, onStatusChange, id }) => {
        return (
          <React.Fragment>
            <Tooltip style={{ cursor: "pointer" }} title="Status">
              <Switch
                checked={value?.isActive}
                onChange={async () => onStatusChange(id)}
                size="small"
                color="primary"
              />
            </Tooltip>
            <Tooltip
              onClick={() => onEdit(value)}
              style={{ cursor: "pointer" }}
              title="Edit"
            >
              <Edit size="small" color="primary" />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ],
  department: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "name",
      title: "Deparment Name",
    },
    {
      id: "description",
      title: "Description",
    },
    {
      id: "status",
      title: "Status",
      renderCell: (value) => (value ? "Active" : "InActive"),
    },
    {
      id: "action",
      title: "Action",
      renderCell: ({ value, onStatusChange, onEdit, id }) => {
        return (
          <React.Fragment>
            <Tooltip style={{ cursor: "pointer" }} title="Status">
              <Switch
                checked={value?.status}
                onChange={() => onStatusChange(id)}
                size="small"
                color="primary"
              />
            </Tooltip>
            <Tooltip
              onClick={() => onEdit(value)}
              style={{ cursor: "pointer" }}
              title="Edit"
            >
              <Edit size="small" color="primary" />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ],
  roleAndPermission: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "name",
      title: "Role",
    },
    {
      id: "isActive",
      title: "Status",
      renderCell: (value) => (value ? "Active" : "InActive"),
    },
    {
      id: "action",
      title: "Action",
      renderCell: ({ value, onStatusChange, onEdit, id }) => {
        return (
          <React.Fragment>
            <Tooltip style={{ cursor: "pointer" }} title="Status">
              <Switch
                checked={value?.isActive}
                onChange={() => onStatusChange(id)}
                size="small"
                color="primary"
              />
            </Tooltip>
            <Tooltip
              onClick={() => onEdit(value)}
              style={{ cursor: "pointer" }}
              title="Edit"
            >
              <Edit size="small" color="primary" />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ],
  user: [
    {
      id: "name",
      title: "Name",
    },
    {
      id: "photo",
      title: "Profile",
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value?.name} placement="top-end">
            {value?.name ? (
              <Avatar
                {...stringAvatar(value?.name)}
                src={EMPLOYEE_IMAGE_BASE_URL + `${value?.photo}`}
                alt={value?.name}
              />
            ) : (
              <Avatar />
            )}
          </Tooltip>
        );
      },
    },
    {
      id: "mobileNo",
      title: "Phone",
    },
    {
      id: "id",
      title: "Emp.ID",
      renderCell: ({ value }) => "#" + value,
    },
    {
      id: "role",
      title: "Role",
      renderCell: ({ value }) => value?.name,
    },
    {
      id: "shiftDetails",
      title: "Shift Time",
      renderCell: ({ value }) =>
        `${moment(value[0]?.startTime).format("LT")} To ${moment(
          value[0]?.endTime
        ).format("LT")}`,
    },
    {
      id: "isActive",
      title: "Status",
      renderCell: ({ value, onStatusChange, id }) => {
        return (
          <React.Fragment>
            <Tooltip style={{ cursor: "pointer" }} title="Status">
              <Switch
                checked={value}
                onChange={() => onStatusChange(id)}
                size="small"
                color="primary"
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
    {
      id: "showContact",
      title: "Show Contact",
      renderCell: ({ row, onShowContactStatus, id }) => {
        return (
          <React.Fragment>
            <Tooltip style={{ cursor: "pointer" }} title="Status">
              <Switch
                checked={row?.showContact}
                onChange={() => onShowContactStatus(id)}
                size="small"
                color="primary"
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
    {
      id: "dailyWorkingHours",
      title: "Last Login",
      renderCell: ({ value }) => {
        return value.length
          ? moment(value[0]?.logoutTime).startOf("minute").fromNow()
          : "NA";
      },
    },
    {
      id: "lastLoginIp",
      title: "Last Login IP",
      // renderCell:({value})=>
    },
    {
      id: "createdByName",
      title: "Created By",
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value?.name} placement="top-end">
            {value?.name ? (
              <Avatar
                {...stringAvatar(value?.name)}
                src={EMPLOYEE_IMAGE_BASE_URL + `${value?.photo}`}
                alt={value?.name}
              />
            ) : (
              <Avatar />
            )}
          </Tooltip>
        );
      },
    },
    {
      id: "createdAt",
      title: "Created At",
      renderCell: ({ value }) => `${moment(value).format("DD/MM/YYYY | LT")}`,
    },
    {
      id: "rating",
      title: "Rating",
      renderCell: () => <Rating name="read-only" value={3} readOnly />,
    },
    {
      id: "action",
      title: "Action",
      renderCell: ({ value, menuProps: { open, onClick } }) => {
        return (
          <React.Fragment>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onClick(e, value);
              }}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <MoreHoriz />
            </IconButton>
          </React.Fragment>
        );
      },
    },
  ],
  device: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "name",
      title: "Machine",
    },
    {
      id: "phone",
      title: "Phone",
    },
    {
      id: "email",
      title: "Email",
    },
    {
      id: "outgoing",
      title: "OutGoing",
    },
    {
      id: "status",
      title: "Status",
      renderCell: ({ value }) => (value ? "Active" : "InActive"),
    },
    {
      id: "action",
      title: "Action",
      renderCell: ({ value, id, onEdit, onStatusChange }) => {
        return (
          <React.Fragment>
            <Tooltip style={{ cursor: "pointer" }} title="Status">
              <Switch
                checked={value?.status}
                onChange={() => onStatusChange(id)}
                size="small"
                color="primary"
              />
            </Tooltip>
            <Tooltip
              onClick={() => onEdit(value)}
              style={{ cursor: "pointer" }}
              title="Edit"
            >
              <Edit size="small" color="primary" />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ],
  bankDetail: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "photo",
      title: "Bank Logo",
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value?.name} placement="top-end">
            {value?.branch ? (
              <Avatar
                {...stringAvatar(value?.branch)}
                src={BANK_LOGO_BASE_URL + `/${value?.image}`}
                alt={value?.branch}
              />
            ) : (
              <Avatar />
            )}
          </Tooltip>
        );
        // return value?.image;
      },
    },
    {
      id: "accountNo",
      title: "Account Number",
    },
    {
      id: "ifsc",
      title: "IFSC",
    },
    {
      id: "holderName",
      title: "Holders Name",
    },
    {
      id: "branch",
      title: "Branch",
    },
    {
      id: "status",
      title: "Status",
      renderCell: ({ value, onStatusChange, id }) => {
        return (
          <React.Fragment>
            <Tooltip style={{ cursor: "pointer" }} title="Status">
              <Switch
                checked={value}
                onChange={() => onStatusChange(id)}
                size="small"
                color="primary"
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
    {
      id: "action",
      title: "Action",
      renderCell: ({ value, onEdit }) => {
        return (
          <Tooltip
            onClick={() => onEdit(value)}
            style={{ cursor: "pointer" }}
            title="Edit"
          >
            <Edit size="small" color="primary" />
          </Tooltip>
        );
      },
    },
  ],
  contactDetail: [
    {
      id: "id",
      title: "#ID",
      renderCell: (value) => `#${value.id}`,
    },
    {
      id: "name",
      title: "Name",
    },
    // {
    //   id: "roleName",
    //   title: "Role",
    //   renderCell: ({ value }) => value?.name || "",
    // },
    {
      id: "phoneNo",
      title: "Phone Number",
    },
    {
      id: "email",
      title: "Email",
    },
    {
      id: "departmentName",
      title: "Department",
      // renderCell: ({ value }) => value?.name || "",
    },
    {
      id: "status",
      title: "Status",
      renderCell: ({ value, onStatusChange, id }) => {
        return (
          <React.Fragment>
            <Tooltip style={{ cursor: "pointer" }} title="Status">
              <Switch
                checked={value}
                onChange={() => onStatusChange(id)}
                size="small"
                color="primary"
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
    {
      id: "action",
      title: "Action",
      renderCell: ({ value, onEdit }) => {
        return (
          <Tooltip
            onClick={() => onEdit(value)}
            style={{ cursor: "pointer" }}
            title="Edit"
          >
            <Edit size="small" color="primary" />
          </Tooltip>
        );
      },
    },
  ],
  callLogs: [
    {
      id: "user",
      title: "Attended By",
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value?.name} placement="top-end">
            {value?.name ? (
              <Avatar
                {...stringAvatar(value?.name)}
                src={EMPLOYEE_IMAGE_BASE_URL + `${value?.photo}`}
                alt={value?.name}
              />
            ) : (
              <Avatar />
            )}
          </Tooltip>
        );
      },
    },
    {
      id: "type",
      title: "Type",
    },
    {
      id: "mobile1",
      title: "Contacted On",
      renderCell: ({ row, value, isMobileHide }) => {
        return (
          <Grid>
            {isMobileHide ? (
              <Fab
                color="primary"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  makeCall(row?.lead?.name, value?.mobile1);
                }}
                sx={{ zIndex: "inherit" }}
              >
                <Call
                  style={{ fill: "white", fontSize: "20px" }}
                  className="animate__animated animate__tada animate__infinite infinite"
                />
              </Fab>
            ) : (
              <a
                style={{
                  background: "#9747FF",
                  padding: "6px 10px",
                  textDecoration: "none",
                  color: "#fff",
                  borderRadius: "5px",
                  zIndex: "inherit",
                }}
                href={`tel:${value?.mobile1}`}
                onClick={(e) => {
                  e.stopPropagation();
                  makeCall(row?.lead?.name, value?.mobile1);
                }}
              >
                {value?.mobile1}
              </a>
            )}
          </Grid>
        );
      },
    },
    {
      id: "callDuration",
      title: "Call Duration",
      renderCell: ({ value }) => {
        return formatTime(+value * 1000);
      },
    },
    {
      id: "createdAt",
      title: "Created At",
      renderCell: ({ value }) => {
        return moment(value).format("DD-MM-YYYY LT");
      },
    },
  ],
  task: [
    {
      id: "id",
      title: "#ID",
    },
    {
      id: "destination",
      title: "Destination",
    },
    {
      id: "date",
      title: "Date",
    },
    {
      id: "user_id",
      title: "User Id",
    },
    {
      id: "branch_id",
      title: "Branch Id",
    },
  ],
  totalFollowups: [
    {
      id: "id",
      title: "#ID",
    },
    {
      id: "note",
      title: "Note",
    },
    {
      id: "followUpStatus",
      title: "Follow Up Status",
    },
    {
      id: "time",
      title: "Time",
    },
    {
      id: "date",
      title: "Date",
      renderCell: ({ row }) => dayjs(row?.date).format("YYYY-MM-DD"),
    },
  ],
  callDetails: [
    {
      id: "id",
      title: "#ID",
    },
    {
      id: "name",
      title: "Name",
      renderCell: ({ row }) => row?.lead?.name,
    },
    // {
    //   id: "mobile1",
    //   title: "Mobile",
    // },
    {
      id: "leadtConvertType",
      title: "Lead Convert Type",
      renderCell: ({ row }) => row?.lead?.leadConvertType?.name,
    },
    {
      id: "status",
      title: "Status",
      renderCell: ({ row }) => row?.lead?.status?.name,
    },
    {
      id: "schedule",
      title: "Schedule",
      renderCell: ({ row }) => row?.lead?.schedule,
    },
    {
      id: "type",
      title: "Type",
    },
    // {
    //   id: "callDuration",
    //   title: "Call Duration",
    // },
  ],
  // incomingCalls: [
  //   {
  //     id: "id",
  //     title: "#ID",
  //   },
  //   // {
  //   //   id: "mobile1",
  //   //   title: "Mobile",
  //   // },
  //   {
  //     id: "type",
  //     title: "Type",
  //   },
  //   {
  //     id: "leadId",
  //     title: "Lead ID",
  //   },
  //   {
  //     id: "callDuration",
  //     title: "Call Duration",
  //   },
  // ],
  // missedCalls: [
  //   {
  //     id: "id",
  //     title: "#ID",
  //   },
  //   // {
  //   //   id: "mobile1",
  //   //   title: "Mobile",
  //   // },
  //   {
  //     id: "type",
  //     title: "Type",
  //   },
  //   {
  //     id: "leadId",
  //     title: "Lead ID",
  //   },
  //   {
  //     id: "callDuration",
  //     title: "Call Duration",
  //   },
  // ],
  // outgoingCalls: [
  //   {
  //     id: "id",
  //     title: "#ID",
  //   },
  //   {
  //     id: "name",
  //     title: "Name",
  //     renderCell: ({ row }) => row?.lead?.name,
  //   },
  //   // {
  //   //   id: "mobile1",
  //   //   title: "Mobile",
  //   // },
  //   {
  //     id: "leadtConvertType",
  //     title: "Lead Convert Type",
  //     renderCell: ({ row }) => row?.lead?.leadConvertType?.name,
  //   },
  //   {
  //     id: "status",
  //     title: "Status",
  //     renderCell: ({ row }) => row?.lead?.status?.name,
  //   },
  //   {
  //     id: "schedule",
  //     title: "Schedule",
  //     renderCell: ({ row }) => row?.lead?.schedule,
  //   },
  //   {
  //     id: "type",
  //     title: "Type",
  //   },
  //   // {
  //   //   id: "leadId",
  //   //   title: "Lead ID",
  //   // },
  // ],
  tags: [
    {
      id: "id",
      title: "#ID",
    },
    {
      id: "name",
      title: "Name",
    },
    {
      id: "action",
      title: "Action",
      renderCell: ({ value, onStatusChange, id }) => {
        return (
          <React.Fragment>
            <Tooltip style={{ cursor: "pointer" }} title="Status">
              <Switch
                checked={value?.status}
                onChange={() => onStatusChange(id)}
                size="small"
                color="primary"
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ],
};

/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {TextField, MenuItem, Autocomplete, Box, Paper, Button, Grid} from "@mui/material";
import {useDispatch} from "react-redux";
import {usePagination} from "../hooks/usePagination";
import {$crud} from "../services/CrudFactory";
import {DataGrid} from "@mui/x-data-grid";
import {Delete} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {ConfirmationModal} from "../dialogs/ConfirmationModal";
import Typography from "@mui/material/Typography";

export const TransferData = React.memo(() => {
    const [type, setType] = useState("College");
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const [transferFrom, setTransferFrom] = React.useState([]);
    const [transferTo, setTransferTo] = React.useState("");
    const [transferToValue, setTransferToValue] = React.useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const dispatch = useDispatch();
    const {
        limit,
        page,
        setLimit,
        setPage
    } = usePagination();

    const retrieveData = async (url) => {
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {
                data,
            } = await $crud.retrieve(`web/${url}`);
            return data;
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    }

    const changeTypeData = (value) => {
        switch (value) {
            case "College":
                retrieveData("colleges", {limit, page}).then(res => setLeft(res.colleges));
                break;
            case "University":
                retrieveData("board-and-universities", {
                    limit,
                    page
                }).then(res => setLeft(res.boardAndUniversities));
                break;
            case "Degree":
                retrieveData("degree-and-diplomas", {limit, page}).then(res => setLeft(res.degreeAndDiplomas));
                break;
            case "Lead":
                retrieveData("users-leads", {limit, page}).then(res => setLeft(res.users))
        }
    }

    const handleSubmit = async () => {
        if (!Boolean(transferFrom.length) && !Boolean(transferTo)) return;
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });

            const {type: resType, message} = await $crud.post("update/web/transfer-leads", {
                transferFrom,
                transferTo,
                type
            });
            changeTypeData(type);
            setTransferTo(null);
            setTransferFrom([]);
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: resType, message},
            });
        } catch (e) {
            const {message} = e;
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
        }
    }

    const handleDelete = async (id) => {
        const deleteUrls = {
            College: "college",
            Degree: "degree-and-diploma",
            University: "board-and-university",
            Lead: "user"
        }
        try {
            dispatch({
                type: "LOADER",
                loader: true,
            });
            const {type: resType, message} = await $crud.post(`delete/web/${deleteUrls[type]}`, {id});
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: resType, message},
            });
        } catch (e) {
            dispatch({
                type: "SNACKBAR",
                snackBar: {type: "error", message: e.message},
            });
        } finally {
            dispatch({
                type: "LOADER",
                loader: false,
            });
            changeTypeData(type);
            setShowConfirmationModal(false);
        }


    }

    useEffect(() => {
        setRight(left)
    }, [left])

    useEffect(() => {
        changeTypeData(type)
    }, [type])

    useEffect(() => {
        setRight(_.dropWhile(left, function (d) {
            return transferFrom.includes(d.id)
        }))
    }, [transferFrom])

    return (
        <Grid container spacing={1} justifyContent="center" alignItems="center"
              mb={2}>
            <Grid item  xs={12} display="flex" justifyContent="space-between">
                <Box>
                    <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        label="Type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    >
                        {["College", "University", "Degree", "Lead"].map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box sx={{display: "flex"}}>

                    <Autocomplete
                        options={right}
                        getOptionLabel={(option) => (option.name + "(" + option.noOfLeads) + ")" || option}
                        id="controlled-demo"
                        value={transferToValue}
                        sx={{minWidth: 400, mr: 2, "@media screen and (min-width:768px) and (max-width:900px)":{
                            minWidth:200
                        }}}
                        onChange={(event, newValue) => {
                            setTransferToValue(newValue);
                            setTransferTo(newValue.id);
                        }}
                        size="small"
                        renderInput={(params) => (
                            <TextField {...params} label={type} variant="outlined"/>
                        )}
                    />
                    <Button variant="contained" onClick={handleSubmit}
                            size="small">Transfer</Button>

                </Box>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{maxHeight: "calc(100vh - 180px)", overflow: 'auto'}}
                       className="tableHeader-color">
                    <DataGrid
                        rows={left}
                        columns={[
                            {field: "id", headerName: "ID", minWidth: 50},
                            {field: "name", headerName: "Name", minWidth: 300},
                            {field: "noOfLeads", headerName: "Leads", minWidth: 100},
                            {
                                field: "action",
                                headerName: "Action",
                                minWidth: 100,
                                renderCell: (a) => !Boolean(a.row.noOfLeads) &&
                                    <Tooltip title="Delete"><IconButton onClick={() => {
                                        setShowConfirmationModal(true);
                                        setItemToDelete(a.row.id);
                                    }}><Delete/></IconButton></Tooltip>
                            },
                        ]}
                        initialState={{
                            pagination: {
                                paginationModel: {page, pageSize: limit},
                            },
                        }}
                        sx={{
                            color: "#fff",

                        }}
                        rowSelectionModel={transferFrom}
                        onRowSelectionModelChange={(id) => setTransferFrom(id)}
                        pageSizeOptions={[5, 10, 20]}
                        checkboxSelection
                    />
                    <ConfirmationModal
                        open={showConfirmationModal}
                        onClose={() => setShowConfirmationModal(false)}
                        title={` Delete ${type} ID ${itemToDelete}`}
                        content={<Grid container>
                            <Grid item xs={12}>
                                <Typography variant={"body1"} sx={{mr:3}} component="span">"Are You Sure?</Typography>
                                <Typography variant={"body2"} component="span">Make Sure that Number of Leads is
                                    0.</Typography>
                            </Grid>
                            <Typography variant={"caption"} component="span">Press 'Yes' To Accept , 'Cancel' To
                                Abort"</Typography>

                        </Grid>}
                        onOk={() => {
                            handleDelete(itemToDelete)
                        }}
                    />

                </Paper>
            </Grid>


        </Grid>
    );
})
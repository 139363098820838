/* eslint-disable */

import React, { useState, useCallback, useEffect, memo } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import {
  Badge,
  Button,
  Chip,
  Fab,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  addFilterWithValues,
  addSearchString,
  sidebarLocker,
  addFilterWithLabels,
} from "../redux/actions";
import {
  Download,
  LockOpenOutlined,
  LockOutlined,
  Upload,
  Search,
  Keyboard,
  KeyboardArrowDown,
  NotificationAdd,
} from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { LeadFormButton } from "./LeadFormButton";
import { useDataParams } from "../hooks";
import Menu from "@mui/material/Menu";
import { Filter } from "./Filter";
import _ from "lodash";
import { CourseFormButton } from "./CourseFormButton";
import { AnnouncementModal, CSVPreview } from "../dialogs";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import { ADMIN_ROLE, SUPER_ADMIN_ROLE, TEAM_LEADER_ROLE } from "../constants";
import { $crud } from "../services/CrudFactory";
import { FieldMarketingModalButton } from "./FieldMarketingModalButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { formatDate } from "../helpers/functions";
import { FilterChipContainer } from "./FilterChipContainer";

export const Header = memo(({ count }) => {
  const [showLeadForm, setShowLeadForm] = useState(false);
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
  const [openCsvPreview, setOpenCsvPreview] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(false);
  const [customFilters, setCustomFilters] = useState([]);
  const locked = useSelector((state) => state.sidebarLockerReducer);
  const users = useSelector((state) => state.usersReducer);
  const user = useSelector((state) => state.userReducer);
  const filters = useSelector((state) => state.filterReducer).withValues;
  const customFiltersRedux = useSelector(
    (state) => state.filterReducer
  ).customFilters;
  const { setParams } = useDataParams();
  const { type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [branches, setBranches] = useState([]);
  const [fieldUsers, setFieldUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [filterPayload, setFilterPayload] = useState({});
  let role = localStorage.getItem("role");
  role = JSON.parse(role)?.name;


  const retrieveBranches = async () => {
    try {
      dispatch({
        type: "LOADER",
        loader: true,
      });
      const {
        data: { branches },
      } = await $crud.retrieve("web/branches");
      setBranches(branches);
      if (
        (location.pathname === "/field-marketing/task" ||
          location.pathname === "/field-marketing") &&
        role === "superAdmin"
      ) {
        retrieveFieldMarketingUsers(branches[0]?.id);
      }
    } catch (e) {
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "error", message: e.message },
      });
    } finally {
      dispatch({
        type: "LOADER",
        loader: false,
      });
    }
  };

  const getBranchId = async () => {
    let branchId = await localStorage.getItem("branchInfo");
    let branchInfo = JSON.parse(branchId);
    if (
      (role !== "superAdmin" || role !== "admin") &&
      (location.pathname === "/field-marketing/task" ||
        location.pathname === "/field-marketing")
    ) {
        branchInfo?.id && retrieveFieldMarketingUsers(branchInfo?.id);
      addFilters("branchId", branchInfo?.id, branchInfo?.name);
    }
  };

  const retrieveFieldMarketingUsers = async (id) => {
    try {
      dispatch({
        type: "LOADER",
        loader: true,
      });
      const {
        data: { Users },
      } = await $crud.retrieve("web/field-market-user", { branchId: id });
      setFieldUsers(Users);
    } catch (e) {
      dispatch({
        type: "SNACKBAR",
        snackBar: { type: "error", message: e.message },
      });
    } finally {
      dispatch({
        type: "LOADER",
        loader: false,
      });
    }
  };

  const addFilters = (name, value, label) => {
    if (value === "any") return;
    dispatch(addFilterWithValues({ [name]: value }));
    dispatch(addFilterWithLabels({ [name]: label }));
  };
  const handleSearch = useCallback(
    _.debounce((inputVal) => {
      dispatch(addFilterWithValues({ search: inputVal }));
      dispatch(addFilterWithLabels({ search: inputVal }));
    }, 500),
    []
  );

  const navLinks = [
    {
      title: "Today",
      dynamicRoute: "today",
      countKey: "todayFollowupLeadCount",
    },
    {
      title: "Leads",
      dynamicRoute: "lead",
      countKey: "rawLead",
    },
    {
      title: "Missed",
      dynamicRoute: "missed",
      countKey: "missedFollowUps",
    },
    {
      title: "Future",
      dynamicRoute: "future",
      countKey: "futureFollowUps",
    },
  ];

  const navLinksConvertType = [
    {
      title: "Lead",
      dynamicRoute: "raw",
      countKey: "rawLead",
    },
    {
      title: "Visited",
      dynamicRoute: "visited",
      countKey: "visited",
    },
    {
      title: "Demo",
      dynamicRoute: "demoDone",
      countKey: "demo",
    },
    {
      title: "Customer",
      dynamicRoute: "customer",
      countKey: "customer",
    },
    {
      title: "Re-Targeting",
      dynamicRoute: "retargetting",
      countKey: "retargeting",
    },
    {
      title: "Do Not Disturb",
      dynamicRoute: "doNotDisturb",
      countKey: "doNotDisturb",
    },
    {
      title: "Archived",
      dynamicRoute: "archived",
      countKey: "archived",
    },
    {
      title: "Schedule Visit",
      dynamicRoute: "scheduleVisit",
      countKey: "scheduleVisit",
    },
    {
      title: "Schedule Demo",
      dynamicRoute: "scheduleDemo",
      countKey: "scheduleDemo",
    },
  ];
  const customPages = [
    {
      id: "1",
      title: "Raw Facebook",
      filters: {
        statusId: 1,
        sourceId: 1,
      },
    },
    {
      id: "2",
      title: "Raw Instagram",
      filters: {
        statusId: 1,
        sourceId: 2,
      },
    },
  ];
  const retrieveCustomFilters = async () => {
    const {
      data: { customFilters },
    } = await $crud.retrieve("web/custom-filters");
    return customFilters;
  };

  useEffect(() => {
    retrieveCustomFilters().then((res) => {
      setCustomFilters(res);
    });
  }, []);

  useEffect(() => {
    if (role === "superAdmin" || role === "admin") {
      retrieveBranches();
    }
  }, []);

  useEffect(() => {
    setCustomFilters(customFiltersRedux);
  }, [customFiltersRedux]);

  useEffect(() => {
    if (
      location.pathname === "/field-marketing/task" &&
      role === "superAdmin"
    ) {
      addFilters("branchId", branches[0]?.id, branches[0]?.name);
    }
  }, [branches]);

  useEffect(() => {
    getBranchId();
  }, []);

  const isSmallScreen = useMediaQuery("(max-width:800px)");
  return (
    <Grid
      container
      className="header"
      justifyContent="space-between"
      p={2}
      spacing={2}
    >
      <Grid item>
        <Tooltip title={!locked ? "Lock Sidebar" : "UnLock Sidebar"}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            // sx={{minWidth: "30px", marginRight: "10px"}}
            sx={{
              minWidth: "30px",
              marginRight: isSmallScreen ? "10px" : "10px",
              marginLeft: isSmallScreen ? "20px" : 0,
            }}
            onClick={() => {
              dispatch(sidebarLocker(!locked));
            }}
          >
            {!locked ? <LockOpenOutlined /> : <LockOutlined />}
          </Button>
        </Tooltip>
        {location.pathname !== "/report" &&
          location.pathname !== "/whatsAppForm" &&
          location.pathname !== "/field-marketing" &&
          location.pathname !== "/field-marketing/task" &&
          location.pathname !== "/feesChart" && (
            <React.Fragment>
              <LeadFormButton
                isEdit={false}
                modalProps={{
                  anchor: "right",
                  open: showLeadForm,
                  onOpen: () => {
                    setShowLeadForm(true);
                  },
                  onClose: () => {
                    setShowLeadForm(false);
                    setParams({});
                  },
                  onNew: () => {
                    setParams({});
                  },
                }}
              />
              {(user?.role?.name === ADMIN_ROLE ||
                user?.role?.name === SUPER_ADMIN_ROLE) && (
                <React.Fragment>
                  <Tooltip title="Upload Leads">
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      sx={{ minWidth: "30px", marginRight: "10px" }}
                      onClick={() => navigate("uploadCSV")}
                    >
                      <Upload />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Download Leads">
                    <Button
                      size="small"
                      color="primary"
                      sx={{ minWidth: "30px", marginRight: "10px" }}
                      variant="contained"
                      onClick={() => {}}
                    >
                      <Download />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Create Announcement">
                    <Button
                      size="small"
                      color="primary"
                      sx={{ minWidth: "30px", marginRight: "10px" }}
                      variant="contained"
                      onClick={() => {
                        setShowAnnouncementModal(true);
                      }}
                    >
                      <NotificationAdd />
                    </Button>
                  </Tooltip>
                  <AnnouncementModal
                    open={showAnnouncementModal}
                    onClose={() => setShowAnnouncementModal(false)}
                  />
                  <CSVPreview
                    open={openCsvPreview}
                    onClose={() => setOpenCsvPreview(false)}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        {location.pathname === "/feesChart" && (
          <CourseFormButton isEdit={false} />
        )}
        {location.pathname === "/field-marketing/task" && (
          <FieldMarketingModalButton branches={branches} isEdit={false} />
        )}
      </Grid>
      <Grid item>
        <Grid container>
          {Boolean(customFilters?.length) && (
            <Button
              variant="contained"
              size="small"
              aria-controls={Boolean(anchorEl2) ? "basic-menu2" : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorEl2) ? "true" : undefined}
              onClick={(e) => {
                setAnchorEl2(e.currentTarget);
              }}
              sx={{ mr: 2 }}
              endIcon={<KeyboardArrowDown />}
            >
              Custom Filters
            </Button>
          )}

          {navLinks?.map((link, index) => {
            return (
              <Tooltip
                title={(count && count[link.countKey]) || "0"}
                key={link.title}
              >
                <Badge
                  badgeContent={(count && count[link.countKey]) || "0"}
                  color="warning"
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => navigate(`/leads/${link.dynamicRoute}`)}
                    sx={{ mr: 2 }}
                    color={link.dynamicRoute === type ? "success" : "primary"}
                  >
                    {link.title}
                  </Button>
                </Badge>
              </Tooltip>
            );
          })}
          <Badge
            badgeContent={
              count?.visited +
                count?.customer +
                count?.retargeting +
                count?.archived || "0"
            }
            color="warning"
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <Button
              variant="contained"
              size="small"
              aria-controls={Boolean(anchorEl) ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorEl) ? "true" : undefined}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
              sx={{ mr: 2 }}
              endIcon={<KeyboardArrowDown />}
            >
              Convert To
            </Button>
          </Badge>
          <Tooltip title={count?.previousMonthLeadsCount || "0"}>
            <Badge
              badgeContent={count?.previousMonthLeadsCount || "0"}
              color="warning"
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => navigate(`/leads/previousMonth`)}
              >
                Last 30 Days
              </Button>
            </Badge>
          </Tooltip>
        </Grid>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            className: "tableHeader-color",
            "aria-labelledby": "basic-button",
            sx: {
              minWidth: "180px",
            },
          }}
        >
          {navLinksConvertType?.map((link) => {
            return (
              <MenuItem
                onClick={() => navigate(`/leads/${link.dynamicRoute}`)}
                style={{ justifyContent: "space-between" }}
                selected={link.dynamicRoute === type}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: (theme) => theme.palette.primary.main,
                  },
                }}
                key={link.dynamicRoute}
              >
                <Typography>{link.title}</Typography>
                <Chip label={(count && count[link.countKey]) || "0"} />
              </MenuItem>
            );
          })}
        </Menu>

        <Menu
          id="basic-menu2"
          anchorEl={anchorEl2}
          open={Boolean(anchorEl2)}
          onClose={() => setAnchorEl2(null)}
          MenuListProps={{
            className: "tableHeader-color",
            "aria-labelledby": "basic-button",
            sx: {
              minWidth: "180px",
            },
          }}
        >
          {customFilters?.map((page) => {
            return (
              <MenuItem
                onClick={() => navigate(`/customFiltersPage/${page.id}`)}
                style={{ justifyContent: "space-between" }}
                // selected={link.dynamicRoute === type}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: (theme) => theme.palette.primary.main,
                  },
                }}
              >
                <Typography>{page.title}</Typography>
                {/*<Chip*/}
                {/*    label={count && count[link.countKey] || "0"}/>*/}
              </MenuItem>
            );
          })}
        </Menu>
      </Grid>

      {role === "superAdmin" &&
        (location.pathname === "/field-marketing" ||
          location.pathname === "/field-marketing/task") && (
          <Grid item>
            <select
              className="form-select selectBox"
              onChange={(e) => {
                retrieveFieldMarketingUsers(e.target.value);
                addFilters(
                  "branchId",
                  e.target.value,
                  e.target.options[e.target.options.selectedIndex].innerText
                );
              }}
              // value = {branches[1]?.name}
              style={{
                borderRadius: "5px",
                border: "1px solid #5C4FDD",
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingRight: "60px",
              }}
              aria-label="Default select example"
            >
              <option value="any" disabled>
                Select Branch
              </option>
              {branches?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </Grid>
        )}
      {(role === "superAdmin" ||
        role === "admin" ||
        role === "manager" ||
        role === "teamLeader") &&
        (location.pathname === "/field-marketing" ||
          location.pathname === "/field-marketing/task") && (
          <Grid item>
            <select
              className="form-select selectBox"
              onChange={(e) => {
                addFilters(
                  "UserId",
                  e.target.value,
                  e.target.options[e.target.options.selectedIndex].innerText
                );
              }}
              style={{
                borderRadius: "5px",
                border: "1px solid #5C4FDD",
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingRight: "60px",
              }}
              aria-label="Default select example"
            >
              <option value="any">Select Field User</option>
              {fieldUsers?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </Grid>
        )}

      {(location.pathname === "/field-marketing" ||
        location.pathname === "/field-marketing/task") && (
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#5C4FDD",
                },
              }}
              value={selectedDate}
              onChange={(e) => {
                setSelectedDate(formatDate(e));
                addFilters("taskDate", formatDate(e), formatDate(e));
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  sx={{
                    input: { padding: "6.5px 14px" },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      )}

      {location?.pathname !== "/report" &&
      location.pathname !== "/field-marketing" &&
      location.pathname !== "/field-marketing/task" ? (
        <Grid item display="flex">
          {location?.pathname !== "/feesChart" && (
            <Filter style={{ marginRight: "10px" }} />
          )}
          {location?.pathname !== "/whatsAppForm" && (
            <TextField
              fullWidth
              InputProps={{
                sx: {
                  height: "30px",
                },
                endAdornment: (
                  <IconButton edge="end">
                    <Search />
                  </IconButton>
                ),
              }}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              placeholder="Search"
            />
          )}
        </Grid>
      ) : (
        [ADMIN_ROLE, SUPER_ADMIN_ROLE, TEAM_LEADER_ROLE].includes(
          user?.role?.name
        ) &&
        ![
          "/whatsAppForm",
          "/field-marketing",
          "/field-marketing/task",
        ].includes(location.pathname) && (
          <Grid item>
            <select
              onChange={(e) =>
                addFilters(
                  "SelectedUser",
                  e.target.value,
                  e.target.options[e.target.options.selectedIndex].innerText
                )
              }
              className="form-select selectBox"
              style={{
                borderRadius: "5px",
                border: "1px solid #5C4FDD",
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingRight: "60px",
              }}
              aria-label="Default select example"
            >
              <option value="any">Select User</option>
              {[TEAM_LEADER_ROLE].includes(user?.role?.name)
                ? users
                    ?.filter((u) => u.teamLeaderId === user?.id)
                    ?.map((item) => <option key={item?.id} value={item?.id}>{item.name}</option>)
                : users?.map((item) => (
                    <option key={item?.id} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
            </select>
          </Grid>
        )
      )}

      <Outlet />
    </Grid>
  );
});

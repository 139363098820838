import dayjs from "dayjs";

export const formateDateAndTime = (originalDate) => {
  return dayjs(originalDate).format("YYYY-MM-DD hh:mm:ss");
};

export const formatDate = (originalDate) => {
  return dayjs(originalDate).format("YYYY-MM-DD");
};

// export const formatDate =

export const capitalize = (string) => {
  return (
    string
      ?.split(" ")
      .map((e) => e.slice(0, 1).toUpperCase() + e.slice(1, e.length))
      .join(" ") || ""
  );
};

export const emiCalculator = (
  amount,
  downPayment,
  duration,
  interest = null
) => {
  if (!interest && !!amount && !!downPayment && !!duration) {
    return (Number(amount) - Number(downPayment)) / Number(duration);
  }
};

function stringToColor(string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

export function stringAvatar(name) {
  const newName = name && name?.replace(/\s+/g, " ")?.trim();
  return {
    sx: {
      bgcolor: stringToColor(newName),
    },
    children:
      newName.split(" ").length > 1
        ? `${newName.split(" ")[0][0]?.toUpperCase()}${newName
            .split(" ")[1][0]
            ?.toUpperCase()}`
        : `${newName.split(" ")[0].trim()[0]?.toUpperCase()}`,
  };
}

export const copyToClipboard = (text) => {
  let textField = document.createElement("textarea");
  textField.innerHTML = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};

export const handleCopyLink = (text) => {
  navigator.clipboard.writeText(text);
};

export const formatTime = (time) => {
  // const ms = Math.round(time / 100) % 10;
  const secs = Math.floor(time / 1000) % 60;
  const mins = Math.floor(time / 1000 / 60) % 60;
  const hrs = Math.floor(time / 1000 / 1000 / 60) % 60;
  const formattedHrs = String(hrs).padStart(2, "0");
  const formattedMins = String(mins).padStart(2, "0");
  const formattedSecs = String(secs).padStart(2, "0");
  return `${formattedHrs}:${formattedMins}:${formattedSecs}`;
  // return `${hrs}:${mins}:${secs}`;
};
